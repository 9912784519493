import axios from 'axios';
import uuid from 'react-uuid';
import { authProvider } from 'helpers/ssohelper';

const es_end_point = process.env.REACT_APP_ES_END_POINT;
const api_key = process.env.REACT_APP_ES_API_KEY;
const engine_name = process.env.REACT_APP_ENGINE_NAME;
const intent_end_point = process.env.REACT_APP_INTENT_END_POINT;
const intent_api_key = process.env.REACT_APP_INTENT_API_KEY;

//const userID = authProvider.account.idToken['https://bayer.com/cwid'];

const GetSavedSearch = (uuid) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': api_key,

    }

    const data = {
        //"from":1,
        "size":500,
        "query": {
            "bool": {
                "filter": [
                    {
                        "term": {
                            "scope": engine_name.toUpperCase()
                        }
                    },
                    {
                        "term": {
                            "author": authProvider.account.idToken['https://bayer.com/cwid']
                        }
                    }
                ]
            }
        }
    }

    // return axios.post('https://sba-dev.es.westeurope.azure.elastic-cloud.com/admin-savedsearches/_search', data, {
    //   headers: headers
    // })
    return axios.post(es_end_point + '/admin-savedsearches/_search', data, {
        headers: headers
    })
};

const GetSavedSearchUUID = (uuid,pageName) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': api_key,

    }

    const data = {
        "query": {
            "bool": {
                "filter": [
                     {
                         "term": {
                             "scope": engine_name.toUpperCase()
                         }
                     },
                    // {
                    //     "term": {
                    //         "author": (pageName == "savedsearch" ? "" :authProvider.account.idToken['https://bayer.com/cwid'])
                    //     }
                    // },
                    {
                        "term": {
                            "_id": uuid
                        }
                    }
                ]
            }
        }
    }

    // return axios.post('https://sba-dev.es.westeurope.azure.elastic-cloud.com/admin-savedsearches/_search', data, {
    //   headers: headers
    // })
    return axios.post(es_end_point + '/admin-savedsearches/_search', data, {
        headers: headers
    })
};

const DeleteSavedSearch = (uuid) => {
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': api_key,

    }

    return axios.delete(es_end_point + '/admin-savedsearches/_doc/' + uuid, {
        headers: headers
    })
};

const CreateSavedSearch = (body) => {
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': api_key
    }

    body.scope = engine_name.toUpperCase();
    body.author = authProvider.account.idToken['https://bayer.com/cwid'];

    //Start code for Notification
    body.userName = authProvider.account.userName;

    var filters = {
        "all": []
    }

    body.query.state.filters.forEach((c, index) => {

        var obj = {}; // created object for json
        obj[c.type] = []; // passing name or key of object

        c.values.forEach((b, index) => {
            var obj1 = {};
            obj1[c.field] = b;
            obj[c.type].push(obj1);
        })

        // obj.all = []; // add this object for Date Range Filter
        //   var processingtime = {}
        //   processingtime.from = "";
        //   processingtime.to = "";
        //   obj.all.push(processingtime);

        filters.all.push(obj);

    });
    let tempObj_ = {};// add this object for Date Range Filter
    tempObj_.all = [];
    let tempObj__ = {}
    tempObj__.processingtime = {}
    tempObj__.processingtime.from = "";
    tempObj__.processingtime.to = "";
    tempObj_.all.push(tempObj__);
    filters.all.push(tempObj_);

    

    body.finalNotificationAPIPayload = {
        query : body.query.searchQuery.query,
        filters,
        dateValue: localStorage.getItem('slider') // added obj to set date range value        
    }

    console.log('filterFacet :', body);

    //added for handle date format, previous format - "Mon Jul 10 2023 09:32:27 GMT+0530 (India Standard Time)"
    body.query.searchQuery.filters.forEach((i, index) => {
        if(i.field == "effective_date"){
            if(i.values.length > 0){
                // i.values[0].from = '';
                // i.values[0].to = '';
                i.values= [];
            }
        }
    })

    //End code for Notification
    //return false;
    return axios.post(es_end_point + '/admin-savedsearches/_doc/' + uuid(), body, {
        headers: headers
    })
};
const CreateSavedSearchForCopyLink = (body,uuid) => {
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': api_key
    }

    body.scope = engine_name.toUpperCase();
    body.author = '';
    body.dateValue= localStorage.getItem('slider') // for Copy Search Link - added obj to set date range value        

    return axios.post(es_end_point + '/admin-savedsearches/_doc/' + uuid, body, {
        headers: headers
    })
};
const GetTranslatedResult_Service = (searchValue) => {
    const headers = {
        'Content-Type': 'application/json',
        'apikey': intent_api_key,

    }

    const data = {
        "context": {
            "text": [
                searchValue               
            ],
            "to": "en"
        },
        "service": {
            "provider": "ai.text.translate.deepl.api.v2"
        }
    }

    // return axios.post('https://sba-dev.es.westeurope.azure.elastic-cloud.com/admin-savedsearches/_search', data, {
    //   headers: headers
    // })
    return axios.post(intent_end_point, data, {
        headers: headers
    })
};

export {
    GetSavedSearch,
    DeleteSavedSearch,
    CreateSavedSearch,
    GetSavedSearchUUID,
    CreateSavedSearchForCopyLink,
    GetTranslatedResult_Service
}