import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "reactstrap";
import bayerLogo from "assets/images/bayer_logo.png";
import bayerHeaderLogo from "assets/images/IMS-Logo-Header.png";
import { HeadertabComponent } from "components/HeadertabComponent";
import { Modal, Button } from 'react-bootstrap'
import "./CreateCopyLinkSearch.scss";
import { GetSavedSearch, CreateSavedSearch,CreateSavedSearchForCopyLink } from 'services/savedSearch.service';
import uuid from 'react-uuid';


export const CreateCopyLinkSearch = () => {

  // const [SavedSearchList, setSavedSearchList] = useState([]);
  // useEffect(() => {
  //   GetSavedSearchResult()
  // }, []);

  const [showCopySearchLink, setShowCopySearchLink] = useState(false); // it for modal popup
  
  // Start for SavedSearch Name like title of saved Search
  const [copySearchLink, setCopySearchLink] = useState('');
  
  
//Start for Modal PopUp
  const handleClose = () => {
    setShowCopySearchLink(false);
    setCopySearchLink('');
  }
  
//End for Modal Popup



  // const GetSavedSearchResult = async () => {
  //   let data = {}

  //   const response = await GetSavedSearch(data)
  //     ?.then((response) => {
  //       console.log("responsGet SavedSearch:", response);
  //       return response;
  //     })
  //     .catch((error) => { console.log(error) });

  //   setSavedSearchList(await response.data.hits.hits);
  // }

  const CreateCopyLinkSearch_ = () => {
    let uuid_ = uuid();  
debugger;
    let data = {
      "searchQuery": window.searchUI.searchQuery,
      "state": window.searchUI.state,    
      "lastPushSearchString" :window.searchUI.URLManager.lastPushSearchString  
      //"state": window.state //added for Nested Filter;      
    }

    const d = new Date();
    let tempBody =
    {
      'public': false,
      'scope': '',
      "title": '',
      "query": data,
      "usecase": "Pia_All_SearchByInterest",
      "author": '',
      "usageCount": 0,
      "lastUpdate": d.toJSON(),
      "created": d.toJSON()
    }
    
    CreateSavedSearchForCopyLink(tempBody,uuid_)?.then((response) => {     

      // Copy the text inside the text field
      navigator.clipboard.writeText(window.location.origin + "/#/search/" + uuid_);
      setCopySearchLink(window.location.origin + "/#/search/" + uuid_);
      setShowCopySearchLink(true);
      

      // Alert the copied text
      //alert("Copied the text: " + window.location.origin + "/#/search/" + uuid_);
      //setCopySearchLink(window.location.origin + "/#/search/" + uuid_);
     // setShowCopySearchLink(true);

    // //   navigator.clipboard.writeText(window.location.href);

    // // // Show the copied URL in the modal popup
    // // setCopySearchLink(window.location.href);
    // // setShowCopySearchLink(true);
      
    })
      .catch((error) => { console.log(error) });
  }

  const modalBodyStyle = {
    fontWeight: 500,
    wordWrap: 'break-word', // Add this CSS property to wrap long text
    maxWidth: '400px', 
  }

  return (
    <>

      <a variant="primary" onClick={CreateCopyLinkSearch_} style={{ cursor: "pointer", fontSize: 14, height: "36px", fontWeight: "500", color: "#006bb8" }} >Copy Search Link</a>

      <Modal show={showCopySearchLink} onHide={handleClose} closeButton>
        <Modal.Header closeButton>
        <strong>Copied the text:</strong>
        
        </Modal.Header>
         <Modal.Body style={modalBodyStyle}>
        {copySearchLink}
        </Modal.Body> 

      </Modal>
    </>
  );
};