import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "reactstrap";
import bayerLogo from "assets/images/bayer_logo.png";
import bayerHeaderLogo from "assets/images/IMS-Logo-Header.png";
import { HeadertabComponent } from "components/HeadertabComponent";
import { Modal, Button } from 'react-bootstrap'
import "./CreateSaveSearch.scss";
import { GetSavedSearch, CreateSavedSearch } from 'services/savedSearch.service';


export const CreateSaveSearch = () => {

  const [SavedSearchList, setSavedSearchList] = useState([]);
  useEffect(() => {
    GetSavedSearchResult()
  }, []);

  const [show, setShow] = useState(false); // it for modal popup
  const [showMessage, setShowMessage] = useState(false); // it for modal popup

  // Start for SavedSearch Name like title of saved Search
  const [title, setTitle] = useState('');
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  const [updatedTitle, setTitleUpdated] = useState(title);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleTitleClick = () => {
    //  "title" stores input field value
    setTitleUpdated(title);
  };
//End for SavedSearch Name like title of saved Search

//Start for Modal PopUp
  const handleExplicitlyClose = () => {
    setShow(false);
    setShowMessage(false);
  }

  const handleClose = () => {
    setShow(false);

    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 4000);
  }
  const handleShow = () => setShow(true);
//End for Modal Popup



  const GetSavedSearchResult = async () => {
    let data = {}

    const response = await GetSavedSearch(data)
      ?.then((response) => {
        console.log("responsGet SavedSearch:", response);
        return response;
      })
      .catch((error) => { console.log(error) });

    setSavedSearchList(await response.data.hits.hits);
  }

  const CreateSaveSearch_ = () => {
    if (title == '') {
      alert('Please enter Save Search Name.')
      return false;
    } else {
      
      //  check if SavedSearch name already exist
      let isTitleFound = SavedSearchList.some(element => {
        if (element._source.title === title) {
          return true;
        }
        return false;
      });

      if (isTitleFound) {
        alert('Save Search Name already exist. Please save your searches with a different name.');
        return false;
      }
    }    

    let data = {
      "searchQuery": window.searchUI.searchQuery,
      "state": window.searchUI.state,
      "lastPushSearchString" :window.searchUI.URLManager.lastPushSearchString  

      
      //"state": window.state //added for Nested Filter;
      
    }

    const d = new Date();

    let tempBody =
    {
      'public': false,
      'scope': '',
      "title": title,
      "chkNty": checked,
      "query": data,
      "usecase": "Pia_All_SearchByInterest",
      "author": '',
      "usageCount": 0,
      "lastUpdate": d.toJSON(),
      "created": d.toJSON()
    }
    
    CreateSavedSearch(tempBody)?.then((response) => {      
      handleClose();
    })
      .catch((error) => { console.log(error) });
  }

  return (
    <>

      <a variant="primary" onClick={handleShow} style={{ cursor: "pointer", fontSize: 14, height: "36px", fontWeight: "500", color: "#006bb8" }} >Save Search</a>

      <Modal show={show} onHide={handleExplicitlyClose}>
        <Modal.Header closeButton>
          <Modal.Title>Save Search Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" className="form-control" onChange={handleTitleChange} value={title}></input>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleExplicitlyClose}>
            Close
          </Button> */}
          <label style={{marginRight: "20%", marginBottom: "-10px", cursor: "pointer", fontSize: 14, height: "36px", fontWeight: "500", color: "#006bb8" }}>
          <input type="checkbox" className="checkboxNotification" checked={checked}
          onChange={handleChange} style={{cursor: "pointer"}}></input> Please click here for weekly notification
          </label>
          <Button variant="primary" onClick={() => { CreateSaveSearch_() }}>
            Save Search
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMessage} onHide={handleClose}>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body style={{ fontWeight: 500 }}>
          Selected Search Saved. Please click on "My Saved Search" link to view your save searches.
        </Modal.Body>

      </Modal>
    </>
  );
};
