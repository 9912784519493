import React from "react";
import { Row, Container, Col } from "reactstrap";
import bayerLogo from "assets/images/bayer_logo.png";
import bayerHeaderLogo from "assets/images/IMS-Logo-Header1.png";
import { HeadertabComponent } from "components/HeadertabComponent";
import "./Appheader.scss";

export const Appheader = () => {
  return (
    <Container fluid className="appHeader">
      <Row className="headerRow">
        <Col md="10" xs="10" className="header_alignment">
          {/* <h1 className='header_first'>
            IMS User Interface – Search for Regulations@Bayer
          </h1> */}
          <div>
            <img src={bayerHeaderLogo} alt="header logo" style={{height:"72px"}}></img>
          </div>
          {/* <h5 className='header2'>
            Integrated Management System - Search for Regulations and Processes
          </h5> */}
        </Col>
        <Col md="2" xs="2">
          <img src={bayerLogo} className="headeimgCls" alt="application logo" />
        </Col>
      </Row>
      <hr></hr>
      {/* <HeadertabComponent /> */}
      
    </Container>
  );
};
