import React from "react";
import { Row, Container, Col } from "reactstrap";

import "./Appfooter.scss";

export const Appfooter = () => {
  const OpenMailBox = () => {
    
    window.location.href = "mailto:ims_user_interface_feedback@bayer.com?subject=IMS Feedback&body=" + "";

  };
  return (
    <Container fluid className="footer_continer">
      <Row className="footer_class">
        <Col xs="10" md="10">
          <div className="footer_section_first">
            <p><a href="/#/imprint">Imprint // Copyright <i class="fa fa-copyright copyright" aria-hidden="true"></i> Bayer AG</a></p>
          </div>
        </Col>
        <Col xs="2" md="2">
          <div>
            <p className="hrefColor">
            <a onClick={() => { OpenMailBox() }} style={{cursor:"pointer"}}>How can we help?</a> // <span className="footer_span">IMS</span>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
