import React ,{ useEffect,useRef  } from "react";
import { AzureAD, AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';
import './Login.scss';
import { authProvider,loginRequest } from 'helpers/ssohelper';
import { useNavigate } from 'react-router-dom';
import {
    SELECT_LOCATION_MESSAGE,
  } from 'constants/general';
  import * as Msal from "msal";
  // if using cdn version, 'Msal' will be available in the global scope
  import { useIsAuthenticated, useMsal } from '@azure/msal-react';
// import { SignInbutton } from 'pages/SignInbutton';
import { routes } from "constants/routes";



export const Login = ()=>{
  const buttonNameRef = useRef()
  const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();
    useEffect(()=>{
      // setTimeout(() => {
      //   document.getElementById("myCheck").click();
      // },2000)
      
    },[])
    // const { location, showValidationMessage } = props;
  
    const validateLocation = () => {
      console.log(SELECT_LOCATION_MESSAGE);
    };
  
    const validateTokenResponse = (errorMessage) => {
      showValidationMessage(errorMessage);
    };
  console.log(authProvider,'auth')
    return (
        
      <AzureAD provider={authProvider}>
        {({ login, authenticationState, accountInfo }) => {
          const isInProgress = authenticationState === AuthenticationState.InProgress;
          const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
          const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
          if (isAuthenticated) {
            console.log('isAuthenticated_AccountInfo : ',accountInfo);
            navigate(routes.home.path);

    
            return <div></div>;
          } else if (isUnauthenticated || isInProgress) {
            
            console.log(isUnauthenticated)
            console.log('login :',login);

            // const { instance } = useMsal();    
            // instance.loginRedirect(loginRequest).catch(e => {
            //     console.log(e);
            // });

            return (
              <button
              id="myCheck"
                onClick={login}
                // disabled={!location}
                ref={buttonNameRef}
                className='ssoLogin'
              >
                login
              </button>
            );
          }
        }}
      </AzureAD>
    );
}