import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import "./SavedSearch.scss";
import { GetSavedSearch,DeleteSavedSearch } from 'services/savedSearch.service';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from 'pages/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
const msalInstance = new PublicClientApplication(msalConfig);
import { authProvider } from 'helpers/ssohelper';

import { routes } from "constants/routes";

const end_point = process.env.REACT_APP_END_POINT;
const search_key = process.env.REACT_APP_SEARCH_KEY;
const engine_name = process.env.REACT_APP_ENGINE_NAME;
const connector = new AppSearchAPIConnector({
  searchKey: search_key,
  engineName: engine_name,
  endpointBase: end_point,
});
export const SavedSearch = (props) => {
  const [SavedSearchList, setSavedSearchList] = useState([]);
  const [ isRecordfound,setIsrecordfound] = useState(false)
 
  useEffect(() => {
    GetSavedSearchResult()
  }, []);

  const GetSavedSearchResult = async () => {
    let data = {}

    const response = await GetSavedSearch(data)
      ?.then((response) => {
        console.log("responsGet SavedSearch:", response);
        return response;
      })
      .catch((error) => { console.log(error) });
      let totalArray = response.data.hits.hits
      if(Object.keys(totalArray).length === 0){
        console.log('herererer')
        setIsrecordfound(true)
      }else setIsrecordfound(false)
    console.log(response.data.hits.hits);
    setSavedSearchList(await response.data.hits.hits);
  }

  const DeleteSavedSearchByUUID = async (uuid) =>{
    let response = await DeleteSavedSearch(uuid)?.then((response) => {
      console.log("responsGet SavedSearch:", response);
      window.location.reload(false);
      return response;
    })
    .catch((error) => { console.log(error) });;
    console.log(response);
  }

  const navigate = useNavigate();

  const RedirectToSearch = (uuid) => {
    navigate(routes.search.path.replace(":searchTerm", uuid));

  };
  
  return (

    <div className="bodyPadding">
      <p className="UserName">{authProvider.account.idToken.name}</p>
      <Tabs
        defaultActiveKey="mySavedSearch"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="mySavedSearch" title="My Saved Search">
          {/* <table id="savedSearch" className="center-SavedSearch" > */}
          <Table striped>
            <thead>
              <tr>
                <th>Name</th>
                <th style={{textAlign: "right"}}>Email enabled for weekly notification</th>
                <th></th>
              </tr>
            </thead>
            {
              isRecordfound ? <p>no records found</p>:
            <tbody>
              {SavedSearchList.map((data) => {
                return (
                  <tr key={data._id}>
                    <td className="savedSearchTitle" onClick={() => { RedirectToSearch(data._id) }}>{data._source.title}</td>
                    <td><input type="checkbox" checked={data._source.chkNty} style={{marginLeft: "90%"}}></input></td>
                    <td><a onClick={() => { DeleteSavedSearchByUUID(data._id) }} className="fa fa-trash-o savedSearchDelete" ></a></td>
                  </tr>
                )
              })
              }
            </tbody>
            }
          </Table>
        </Tab>
      </Tabs>      
      </div>
  );
};
