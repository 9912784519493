import React, { useState, useEffect } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Button } from 'reactstrap';
import { Tooltip } from '@mui/material'

import { useParams } from 'react-router-dom';
import { queryProcessor } from 'core/utils/processor';
import { getSearchResults } from 'services/search.service';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import exlIcon from "assets/images/Doc-Icon-XLS.png";
import { Spinner } from 'reactstrap';
import { formatTimeandDate } from "core/utils/commonHelpers";
import CountryWithShortName,{ LanguageWithShortName } from "services/countrywithshortname";

const end_point = process.env.REACT_APP_END_POINT;
const search_key = process.env.REACT_APP_SEARCH_KEY;
const engine_name = process.env.REACT_APP_ENGINE_NAME;
const connector = new AppSearchAPIConnector({
  searchKey: search_key,
  engineName: engine_name,
  endpointBase: end_point,
});

const ExportExcel = ({excelData, fileName,searchValue}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const [IsLoading, setIsLoading] = useState(false);
    
    const params = useParams();
    const [Config, setConfig] = useState({});
  const [isload, setisload] = useState(false);

    const listExcelExportData = [];
    const makeData = (resultData,searchValue) =>{    
      console.log('resultData :',resultData);
        resultData.data.results.forEach((td,index) => { 
          let objExcelExportData ={
            Title : '',
            URL : '',
            Search_Term : '',
            Effective_Date : ''
          };     
          objExcelExportData.Title = td.title && td.title.raw ? td.title.raw : '';
          if (td.url && td.url.raw) {    // added to fix lifedoc multiple url
            const urls = Array.isArray(td.url.raw) ? td.url.raw : [td.url.raw];
            objExcelExportData.URL = urls[0]; // Take the first URL if multiple URLs are present
          } else {
            objExcelExportData.URL = '';
          }
          objExcelExportData.Search_Term = searchValue;
          objExcelExportData.Effective_Date = td.effective_date && td.effective_date.raw ? formatTimeandDate(td.effective_date.raw) : '';
          
          listExcelExportData.push(objExcelExportData);            
        })
        console.log("listExcelExportData : ",listExcelExportData);
      }

    const getSearch = (searchValue)=>{
      
        // let data = {
        //   "query": searchValue,
        //   "page": {
        //     "current": 1,
        //     "size": 50
        // },
        //   "facets": {
        //     "source_name_ims":
        //       {
        //         "type": "value",
        //         "name": "source_name",
        //         "sort": { "value": "asc" },
        //         "size": 200
        //       },
        //     "regulation": { type: 'value', size: 250 }
        //   }
    
        var filters =  {
          "all": []
        }

        var search_fields = {
          
            "system_title":{
               "weight":3.5
            },
            "title":{
               "weight":30.5
            },
            "tags":{
               "weight":1.5
            },
            "division":{
               "weight":1.5
            },
            "country":{
               "weight":1.5
            },
            "description":{
               "weight":1.0
            },
            "body":{
               "weight":1.2
            },
            "approved":{
               "weight":0.0
            },
            "approver":{
               "weight":0.0
            },
            "author":{
               "weight":0.0
            },
            "comments":{
               "weight":0.0
            },
            "contact":{
               "weight":0.0
            },
            "created_by":{
               "weight":0.0
            },
            "doc_id":{
               "weight":0.0
            },
            "editor":{
               "weight":0.0
            },
            "enabling_and_support":{
               "weight":0.0
            },
            "extension":{
               "weight":0.0
            },
            "label":{
               "weight":0.0
            },
            "languages":{
               "weight":0.0
            },
            "org_level_1":{
               "weight":0.0
            },
            "org_level_2":{
               "weight":0.0
            },
            "org_level_3":{
               "weight":0.0
            },
            "org_level_4":{
               "weight":0.0
            },
            "org_level_5":{
               "weight":0.0
            },
            "owned_by":{
               "weight":0.0
            },
            "perm_id":{
               "weight":0.0
            },
            "procedures":{
               "weight":0.0
            },
            "process":{
               "weight":0.0
            },
            "regulation":{
               "weight":0.0
            },
            "site":{
               "weight":0.0
            },
            "source_name_ims":{
               "weight":0.0
            },
            "source_sublevel":{
               "weight":0.0
            }
         
        }

        var boosts = {
          
   "procedures":[
      {
         "type":"value",
         "value":"Procedures",
         "operation":"add",
         "factor":100.0
      }
   ],
   "source_name_ims":[
      {
         "type":"value",
         "value":"MARGO",
         "operation":"add",
         "factor":200.0
      }
   ],
   "source_sublevel":[
      {
         "type":"value",
         "value":"REGS-DE01 - CMS - Global Regulations",
         "operation":"add",
         "factor":20.0
      }
   ]

        }
        
        // Start - MuleSoft API HR data - Priority user's country and language
        debugger; 
        if (sessionStorage.MuleApiHrData){     
      const MuleApiHrData = JSON.parse(sessionStorage.MuleApiHrData);
      console.log("MuleApiHrData:", MuleApiHrData);

      if (MuleApiHrData.businessAddressCountry && MuleApiHrData.myServicesLanguage) {
        console.log("MuleApiHrDataFound");

        console.log("CountryAndLanguageShortCode :", CountryWithShortName);
        var CountryFullName = CountryWithShortName.filter(function (item) {
          return item.code.toLowerCase() == MuleApiHrData.businessAddressCountry.toLowerCase();
        });
        console.log("CountryFullName :", CountryFullName);

        console.log("LanguageWithShortNameCode :", LanguageWithShortName);
        var LanguageFullName = LanguageWithShortName.filter(function (item) {
          return item.code.toLowerCase() == MuleApiHrData.myServicesLanguage.toLowerCase();
        });
        console.log("LanguageFullName :", LanguageFullName);

        var boosts = {
          "country": [
            {
              "type": "value",
              // "value": MuleApiHrData.businessAddressCountry,
              "value": CountryFullName[0].name,
              "operation": "add",
              "factor": 1000.0
            }
          ],
          "languages": [
            {
              "type": "value",
              // "value": MuleApiHrData.myServicesLanguage,
              "value": LanguageFullName[0].name,
              "operation": "add",
              "factor": 1000.0
            }
          ]
        }
      } else {
        console.log("MuleApiHrDataNotFound");
      }}
      // End - MuleSoft API HR data - Priority user's country and language

        window.searchUI.state.filters.forEach((c,index) => {           

          var obj = {}; // created object for json
          obj[c.type] = []; // passing name or key of object
              
          c.values.forEach((b,index) => {
            var obj1 = {};      
            obj1[c.field] = b;
            obj[c.type].push(obj1);
          })
          
          filters.all.push(obj);
          
        });   
        
        console.log('filterFacet :',filters);        
        
        let data = {
          "query": window.searchUI.searchQuery.query,
          "page": {
            // "current": window.searchUI.state.current,
            // "size": window.searchUI.state.resultsPerPage
            "current":1,
            "size": 1000
                  },
                  filters
                ,boosts,
                "precision":8,
                search_fields
        }
        


        console.log('Data :', data);
        getSearchResults(data)
          ?.then((response) => {
            console.log("response:" ,response);
            
            makeData(response, searchValue);
            let proccedQuery = queryProcessor(searchValue, connector,data);
            
            setConfig(proccedQuery);
            setTimeout(() => {
                SaveExcelFile("Exported Data");
              setisload(true);
            }, 3000);
          })
          .catch((error) => {console.log(error)});
      }

    const SaveExcelFile = (fileName) =>{
        // const ws = XLSX.utils.json_to_sheet(excelData);
        const ws = XLSX.utils.json_to_sheet(listExcelExportData);
        const wb= {Sheets: {'data': ws}, SheetNames:['data']};
        const excelBugger = XLSX.write(wb, {bookType: 'xlsx', type:'array'});
        const data = new Blob([excelBugger], {type:fileType});
        setIsLoading(false);
        FileSaver.saveAs(data,fileName + fileExtension);
    }
    
    const exportToExcel = async (excelData,fileName) => {
      let param = new URLSearchParams(window.location.search); 
      let queryStringValue = param.get('q');
      setIsLoading(true);
      
      getSearch(searchValue);
    }
    return(
        <>
        {
          IsLoading &&
          <div className="loader">
            <Spinner color="white" />
          </div>
        }
        <Tooltip title = "Excel Export">
            {/* <Button variant="contained"
            onClick={(e) => exportToExcel(excelData,fileName)} color="primary"
            style={{cursor: "pointer", fontSize:14}}
            >Excel Export
            </Button> */}

            <a onClick={(e) => exportToExcel(excelData,fileName)} style={{cursor: "pointer", fontSize:14,height: "36px", fontWeight:"500"}}><img src={exlIcon} style={{width: "18%", marginBottom:"-5px"}}/>Export Results</a>

        </Tooltip>
        </>
    )
}
export default ExportExcel;