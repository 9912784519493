import React, { useState } from "react";
import { EuiIcon } from "@elastic/eui";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import arrow_right from "assets/images/landingpage/Link-Pfeil-greeen.png";
// import SearchIcon from '@mui/icons-material/Search';
import "./HomesearchBox.scss";

export const HomesearchBox = (props) => {
  const [searchValue, setsearchValue] = useState("");

  const [updated, setUpdated] = useState(searchValue);

  const handleChange = (event) => {
    setsearchValue(event.target.value);
  };

  const handleClick = () => {
    if (searchValue === "" || null) {
      toast.warn("please add text to continue!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      var currentURL = new URL(window.location.href);
      var derivedParameters = searchValue;

      // Remove existing parameters
      currentURL.search = "";

      // Remove hash fragment
      currentURL.hash = "";
    
      // Add custom parameters
      currentURL.searchParams.set("q", derivedParameters);
      currentURL.searchParams.set("size", "n_20_n"); 
      currentURL.searchParams.set("", "");     
    
      // Navigate to the new URL
      window.location.href = currentURL.toString();

      // props.getsubmit(searchValue);
    }
    
    
    
  };
  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.charCode === 13) {
      handleClick();
    }
  };
  return (
    <div className="div_form_">
      <div className="row" style={{marginRight:"0px"}}>
        <div className="col-12" style={{marginTop: "18px", display: "flex"}}>
          <div className="search-box-home" style={{width: "72%"}}>
            <input
              type="text"
              id="searchValue"
              name="searchValue"
              className="input_form_"
              onChange={handleChange}
              value={searchValue}
              onKeyPress={handleKeypress}
              placeholder="Search for Regulations and Processes"
            />

            <button className="btn_button" onClick={handleClick} style={{width:"48px",height:"50px"}}>
              <i class="fa fa-search search_icon"></i>
            </button>
          </div>
          <div className="saved-search-link-home" style={{width: "28%"}}>
            <ToastContainer />
            <a href="/#/SavedSearch" className="my_saved_search_home"  style={{marginTop: "33px"}}>
              <img src={arrow_right}  style={{marginTop: "33px"}}/> <span className="span_cls">My Saved Searches</span>
            </a>
          </div>
        </div>
        {/* <button className="btn_button" onClick={handleClick}><SearchIcon /></button> */}

      </div>
    </div>
  );
};
