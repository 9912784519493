export const routes = {
    search: {
      key: null,
      path: '/search/:searchTerm'
    },
    home: {
      key: null,
      path: '/home'
    },
    savedSearch: {
      key: null,
      path: '/SavedSearch/'
    },
    login:{
      key:null,
      path:'/'
    },
    imprint: {
      key: null,
      path: '/imprint'
    },
    aboutims: {
      key: null,
      path: '/aboutims'
    },
    countryorg: {
      key: null,
      path: '/countryorganisation'
    },
    globelef: {
      key: null,
      path: '/globelef'
    },
    searchpage: {
      key: null,
      path: '/searchpage'
    },
    feedback: {
      key: null,
      path: '/feedback'
    }
}
