import React, { useState, useEffect } from "react";

import { Facet } from "@elastic/react-search-ui";
import "./Sidecontent.scss";
import { MultiCheckboxFacet } from "@elastic/react-search-ui-views";
import Slider from "@mui/material/Slider";
import { format ,subMonths} from "date-fns";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const Sidecontent = (props) => {
  const [ischecked, setischecked] = useState(false);
  const [ischeckedinput, setischeckedinput] = useState(false);
  const [proceduresCount, setProceduresCount] = useState(0);
  const [kwoledgedocumentData, setkwoledgedocumentData] = useState(false);

  const [checkboxesChecked, setCheckboxesChecked] = useState(0);

function handleCheckboxChange(event) {// added to show the source_sublevel field using onclick
  const { checked } = event.target;
  setCheckboxesChecked(prevCount => checked ? prevCount + 1 : prevCount - 1);
}

  const marks = [
    {
      value: 0,
      label: "all",
    },
    {
      value: 20,
      label: "1w",
    },
    {
      value: 40,
      label: "1m",
    },
    {
      value: 60,
      label: "3m",
    },
    {
      value: 80,
      label: "6m",
    },
    {
      value: 100,
      label: "1yr",
    },
  ];

  function valuetext(value,label) {
    return `${value}`;
  }
  // const ClearFilters = () => {
  //   window.location.assign(
  //     window.location.origin + "#/search/" + window.searchUI.searchQuery.query
  //   );
  // };
  const ClearFilters = () => {
    window.onbeforeunload = function() {
    localStorage.clear(); // Clear the local storage
  };
    window.location.assign(
      window.location.origin +
        //"#/search/*" +
        "?q=" + //added to solve clear filter page opening problem
        (window.searchUI.searchQuery.query === " "
          ? "*"
          : window.searchUI.searchQuery.query + "&size=n_20_n")
    );
  };
  const clickecdInput = () => {
    setischeckedinput(!ischeckedinput);
    // Added below code for check and Uncheck Procedures
    setTimeout(() => {
      if (!ischeckedinput) {
        // add 'False' check bcuz obj not updating immidiataly
        let procedures = window.searchUI.state.facets.procedures[0].data;
        procedures.forEach((element) => {
          let inputs = document.getElementById(
            "example_facet_" + element.value
          );
          if (!inputs.checked) {
            inputs.click();
          }
        });
      } else {
        let procedureFilter = window.searchUI.state.filters.find(
          (filter) => filter.field == "procedures"
        );
        let removeFilterStatus = window.searchUI.removeFilter(
          procedureFilter.field,
          "",
          procedureFilter.type
        );
        console.log("removeFilterStatus:", removeFilterStatus);
      }
    }, 500);
  };
  const clickecd = () => {
    setischecked(!ischecked);
  };
useEffect(()=>{
  setTimeout(()=>{

    let kwoledgeDocument =window.searchUI.state.facets.enabling_and_support[0].data;
  
    if(kwoledgeDocument.length == 0){
      setkwoledgedocumentData(true)
    }else{
      setkwoledgedocumentData(false)
    }
  },500)
})
  useEffect(() => {
    setInterval(() => {
      let proceduresArray = window.searchUI.state.facets.procedures[0].data;
      let result = proceduresArray.reduce((r, d) => r + d.count, 0);
      setProceduresCount(result.toLocaleString("en-US"));
    }, 2000);
  }, [ischeckedinput, ischecked]);
  const SliderFacet = ({ values, setValue }) => {
    //const [sliderValue, setSliderValue] = useState(localStorage.getItem('slider'));
    const [sliderValue, setSliderValue] = useState(localStorage.getItem('sliderSaveCriteria')
                                                   ? localStorage.getItem('sliderSaveCriteria')
                                                   : localStorage.getItem('slider'));

    const handleSliderChange = (event, newValue) => {
      
      localStorage.setItem('sliderSaveCriteria', ''); //to clear saved date range criteria from local storage
      setSliderValue(newValue);
    localStorage.setItem('slider', newValue)
     
      let from;
      let showfilter;
      if (newValue == 0) {
        showfilter=false;
        from = "1950-10-05T14:48:00.000Z";
      } 
      else if (newValue === 20) {
        showfilter=true;
        from = format(new Date().setDate(new Date().getDate() - 7));
      } 
      
      else if (newValue === 40) {
        showfilter=true;
        from = format(new Date().setDate(new Date().getDate() - 30));

      } else if (newValue === 60) {
        showfilter=true;
        from = format(new Date().setDate(new Date().getDate() - 90));

      } else if (newValue === 80) {
        showfilter=true;
        from = format(new Date().setDate(new Date().getDate() - 180));

      } else if (newValue === 100) {
        showfilter=true;
        from = format(new Date().setDate(new Date().getDate() - 365));

      }
      updating(from,newValue,showfilter)

    };

    return (
      <Slider
        aria-label="Custom marks"
        value={sliderValue}
        onChange={handleSliderChange}
        getAriaValueText={valuetext}
        step={10}
        valueLabelDisplay="off"
        marks={marks}
        track={false}
        min={0}
      />
    );
  };
  const updating = (from,value,showfilter)=>{
    localStorage.setItem('slider', value)
    props.getEffectiveDate(from,showfilter)
  }
  
  return (
    <div>
      <p>
        <span className="side_heading">FILTERS</span>
        <a
          className="clearFilter"
          onClick={() => {
            ClearFilters();
          }}
        >
          Clear Filters
        </a>
      </p>

      {/* <Sorting
        label={"Sort By"}
        sortOptions={[
          {
            name: "Title",
            value: "title",
            direction: "asc",
          },
        ]}
      /> */}

      {/* <Facet
        field="suggest"
        label="suggest"
        filterType="all"
        isFilterable={false}
      />
      <Facet field="tags" label="tags" filterType="all" isFilterable={false} /> */}
      <table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tbody>
          <tr>
            <td class="sui-facet__title">
              <label style={{ padding: "20px 5px 20px 20px" }}>
                System/Library
              </label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    Filtering by the source systems of documents.
                  </Tooltip>
                )}>
                <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
              </OverlayTrigger>
              {/* <div
              title="Filtering by the source systems of documents"
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            </td>
          </tr>
          <tr>
            <td onChange={handleCheckboxChange}>
              <div style={{ marginTop: "-5%" }}>
                <Facet
                  field="source_name_ims"
                  filterType="any"
                  isFilterable={false}
                  view={MultiCheckboxFacet}
                  onChange={handleCheckboxChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {checkboxesChecked > 0 && (
                <Facet
                field="source_sublevel"
               // label="Source SubLevel"
               searchPlaceholder="Filter"
                filterType="any"
                isFilterable={true}
                view={MultiCheckboxFacet}
                
                />
              )}
            </td>
          </tr>

        </tbody>
      </table>
      {/* <Facet
        field="source_name_ims"
        label="System/Library"
        filterType="all"
        isFilterable={false}
        view={MultiCheckboxFacet}
      /> */}

      <table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tr>
          <td class="sui-facet__title">
            <label
              style={{ padding: "20px 5px 0px 20px", marginBottom: "17%" }}
            >
              Regulation Type
            </label>
            {/* <div
              title="Filtering by the type of regulation and the associated subtypes"
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
              overlay={(props) => (
                <Tooltip {...props}>
                  Filtering by the type of regulation and the associated subtypes
                </Tooltip>
              )}>
              <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <div style={{ marginTop: "-16%" }}>
            <Facet
              field="regulation"
              label=""
              filterType="any"
              isFilterable={false}
              view={MultiCheckboxFacet}
            />
            {
              kwoledgedocumentData ? 
              <div className="kwDiv">
               <input
                type="checkbox"
                checked={false}
              />
            <span className="procedurs_span">Knowledge Document</span>
            <span className="countDisplayKW">{0}</span>
            </div>
              :
            <Facet
              field="enabling_and_support"
              // label="KNOWLEDGE DOCUMENT"
              label=" "
              filterType="any"
              isFilterable={false}
              view={MultiCheckboxFacet}
              className="newFacet"
            />
            }
          </div>
          {/* <div style={{ marginTop: "-35px" }}>
            <Facet
              field="enabling_and_support"
              // label="KNOWLEDGE DOCUMENT"
              label=" "
              filterType="all"
              isFilterable={false}
              view={MultiCheckboxFacet}
            />
          </div> */}
          {/* <div style={{ marginTop: "-30px" }}>
            <Facet
              field="procedures"
              label="procedures"
              filterType="all"
              isFilterable={false}
              view={MultiCheckboxFacet}
            />
          </div> */}
        </tr>
      </table>

      <table
        class="sui-facet_tree"
        style={{ width: "-webkit-fill-available", marginTop: "-25px" }}
      >
        <tr>
          <div className="procedurs_div">
            <span onClick={() => clickecd()}>
              {ischecked ? (
                <i class="fa fa-caret-down" aria-hidden="true"></i>
              ) : (
                <i class="fa fa-caret-right" aria-hidden="true"></i>
              )}
              <input
                type="checkbox"
                id="procedures"
                name="procedures"
                checked={ischeckedinput}
                onClick={() => clickecdInput()}
              />
            </span>
            <span className="procedurs_span">Procedures</span> 
            <span className="countDisplay">{proceduresCount}</span>
          </div>
        </tr>
        {ischecked && (
          <tr>
            <div style={{ marginTop: "-15%", marginLeft: "8%" }}>
              <Facet
                field="procedures"
                label=""
                filterType="any"
                isFilterable={false}
                view={MultiCheckboxFacet}
                show="50"
              />
            </div>
          </tr>
        )}
      </table>
      {/* <div style={{ marginTop: "32px", paddingTop:"20px", paddingBottom:"20px" }}>
        <div >
          <Facet
            field="regulation"
            label="Regulation Type"
            filterType="any"
            isFilterable={false}
            view={MultiCheckboxFacet}
          />
        </div>
        <div style={{ marginTop: "-32px" }}>
          <Facet
            field="enabling_and_support"
            // label="KNOWLEDGE DOCUMENT"
            label=" "
            filterType="all"
            isFilterable={false}
            view={MultiCheckboxFacet}
          />
        </div>
        <div style={{ marginTop: "-30px" }}>
          <Facet
            field="procedures"
            label="procedures"
            filterType="all"
            isFilterable={true}
            view={MultiCheckboxFacet}
          />
        </div>
      </div> */}
      <div style={{ padding: "20px" }} className="clearfix"></div>
      <table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tr>
          <td class="sui-facet__title">
            <label style={{ padding: "20px 5px 20px 20px" }}>
              Geographical Scope
            </label>
            {/* <div
              title="This filter is only for additional support, the data is displayed here unfiltered from the source systems. Therefore, we can not influence the quality of the data or representation."
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
              overlay={(props) => (
                <Tooltip {...props}>
                  This filter is only for additional support, the data is displayed here unfiltered from the source systems. Therefore, we can not influence the quality of the data or representation.
                </Tooltip>
              )}>
              <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <div style={{ marginTop: "-5%" }}>
            <Facet
              field="country"
              label=""
              filterType="any"
              isFilterable={true}
              view={MultiCheckboxFacet}
            />
          </div>
        </tr>
      </table>
      {/* <div style={{ marginTop: "0px" }}>
        <Facet
          field="country"
          label="Geographical Scope"
          filterType="all"
          isFilterable={true}
          view={MultiCheckboxFacet}

        />
      </div> */}
      {/* <Facet
        field="extension"
        label="FILE TYPE"
        filterType="all"
        isFilterable={true}
      /> */}

      <table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tr>
          <td class="sui-facet__title">
            <label style={{ padding: "20px 5px 20px 20px" }}>Language</label>
            {/* <div
              title="Filtering according to the language of the document"
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
              overlay={(props) => (
                <Tooltip {...props}>
                  Filtering according to the language of the document.
                </Tooltip>
              )}>
              <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <div style={{ marginTop: "-5%" }}>
            <Facet
              field="languages"
              label=""
              filterType="any"
              isFilterable={true}
              view={MultiCheckboxFacet}
            />
          </div>
        </tr>
      </table>
      {/* <div style={{ marginTop: "32px" }}>
        <Facet
          field="languages"
          label="Language"
          filterType="all"
          isFilterable={true}
          view={MultiCheckboxFacet}
        />
      </div> */}

      <table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tr>
          <td class="sui-facet__title">
            <label style={{ padding: "20px 5px 20px 20px" }}>Author</label>
            {/* <div
              title="Filtering according to the author of the respective document"
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
              overlay={(props) => (
                <Tooltip {...props}>
                  Filtering according to the author of the respective document.
                </Tooltip>
              )}>
              <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <div style={{ marginTop: "-5%" }}>
            <Facet
              field="created_by"
              label=""
              filterType="any"
              isFilterable={true}
            />
          </div>
        </tr>
      </table>
      {/* <div style={{ marginTop: "32px" }}>
        <Facet
          field="created_by"
          label="Author"
          filterType="all"
          isFilterable={true}
        />
      </div> */}

      {/* <Facet field="modified_at" label="modified_at" filterType="any" /> */}
      {/* <Facet field="tags" label="tags" filterType="any" isFilterable={true} /> */}
      
      {/* <Facet
        field="source_sublevel"
        label="Level 1"
        filterType="all"
        isFilterable={true}
      /> */}

 {/* Task 22929- Added to show the tags filter on search page */}

<table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tr>
          <td class="sui-facet__title">
            <label style={{ padding: "20px 5px 20px 20px" }}>Tags</label>
            {/* <div
              title="Filtering according to the Tags of the respective document"
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
              overlay={(props) => (
                <Tooltip {...props}>
                  Filtering according to the Tags of the respective document.
                </Tooltip>
              )}>
              <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <div style={{ marginTop: "-5%" }}>
            <Facet
              field="tags"
              label=""
              filterType="any"
              isFilterable={true}
              view={MultiCheckboxFacet}
            />
          </div>
        </tr>
      </table>


      <table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tr>
          <td class="sui-facet__title">
            <label style={{ padding: "20px 5px 20px 20px" }}>
              Organizations
            </label>
            {/* <div
              title="Filtering according to the respective Organizations structure"
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
              overlay={(props) => (
                <Tooltip {...props}>
                  Filtering according to the respective Organizations structure.
                </Tooltip>
              )}>
              <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <div style={{ marginTop: "-5%" }}>
            <Facet
              field="division"
              label=""
              filterType="any"
              isFilterable={true}
              view={MultiCheckboxFacet}
            />
          </div>
        </tr>
      </table>
      <table class="sui-facet" style={{ width: "-webkit-fill-available" }}>
        <tr>
          <td class="sui-facet__title">
            <label style={{ padding: "20px 5px 20px 20px" }}>
              Effective date
            </label>
            {/* <div
              title="Filtering according to the effective date"
              class="iconCls"
              style={{ width: "24px" }}
            >
              &nbsp;
            </div> */}
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
              overlay={(props) => (
                <Tooltip {...props}>
                  Filtering according to the effective date.
                </Tooltip>
              )}>
              <div class="iconCls" style={{ width: "24px" }}>&nbsp;</div>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <div style={{ marginTop: "-5%" }}>
            <Facet field="effective_date" label="" isFilterable={true} view={SliderFacet} />
          </div>
        </tr>
      </table>
    </div>
  );
};
