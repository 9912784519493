import React, { useState, useEffect } from "react";
import { Results, PagingInfo, ResultsPerPage, Sorting } from "@elastic/react-search-ui";
import { EuiBreadcrumbs, EuiIcon } from "@elastic/eui";
import { formatTimeandDate, getImg } from "core/utils/commonHelpers";
import { Container, Row, Col } from "reactstrap";
import ExportExcel from "Excelexport";
import { useParams } from "react-router-dom";

import { queryProcessor } from "core/utils/processor";
import { getSearchResults } from "services/search.service";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import "./Bodycontent.scss";
import { Modal, Button } from "react-bootstrap";
import { CreateSaveSearch } from "components/CreateSaveSearch";
import { CreateCopyLinkSearch } from "components/CopyLinkSearch";
import { Spinner } from 'reactstrap';


import infoicon from 'assets/images/Icon-Info.png'
import infoiconhover from 'assets/images/Icon-Info-Hover.png'
const end_point = process.env.REACT_APP_END_POINT;
const search_key = process.env.REACT_APP_SEARCH_KEY;
const engine_name = process.env.REACT_APP_ENGINE_NAME;
const connector = new AppSearchAPIConnector({
  searchKey: search_key,
  engineName: engine_name,
  endpointBase: end_point,
});

const linkedby = async (url, isMyDocURLFound, myDocPubResultCount) => {
  if(typeof(url)==='string'){
    url = url.split();
  }
  var currentURL = new URL(window.location.href);
  // var derivedParameters = url[0];

  //Start - Added loop and code logic to check if any url found with 'pub' and 'Approved' then both url should open in browser's tab
  let isMyDocPubResultCountFound = (isMyDocURLFound == true && myDocPubResultCount > 0) ? 1: 0;
  for (let i = 0; i <= isMyDocPubResultCountFound; i++) {
    var derivedParameters = (isMyDocURLFound == true && i == 0) ? url[0].replace(":pub", ":Approved") : url[0];
  //End - Added loop and code logic to check if any url found with 'pub' and 'Approved' then both url should open in browser's tab
    
  // Remove existing parameters
    currentURL.search = "";

    // Remove hash fragment
    currentURL.hash = "";

    // Add custom parameters
    currentURL.searchParams.set("q", `"${derivedParameters}"`);
    currentURL.searchParams.set("size", "n_20_n");
    currentURL.searchParams.set("", "");

    // Navigate to the new URL
    window.open(currentURL.toString());
  }
      
}


export const Bodycontent = ({ wasSearched, searchValue }) => {
  const [IsLoading, setIsLoading] = useState(false);
  let [SearchDetailList_, setSearchDetailList] = useState('');

  let SearchDetailList = {};
  // const params = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const listExcelExportData = [];
  const [clickedUrl, setClickedUrl] = useState("");

  const CustomContextMenu = ({ url, onCopyLink }) => {
    const handleCopyLink = () => {
      // Perform the copy link operation
      onCopyLink(url);
    }
  };
  
  
  const ValidateMultipleUrl_ = async (url) => {
    if(typeof(url)==='string'){
      url = url.split();
    }
    setIsLoading(true);
    let urlLength = url.length;
    const myInterval = setInterval(myTimer, 2500);
    let IsRequestProgress = false;
    let CurrentRequestCount = 0;

    function myTimer() {
      
      if(IsRequestProgress != true){
        IsRequestProgress = true;
        var script = document.body.appendChild(document.createElement("script"));
        script.onload = function () { 
          let geturl
          if(url.length==1)
          {
           geturl=url[0] 
          }
          else
          {
           geturl=url[CurrentRequestCount]
          }     
            window.open(geturl, '_blank');         
            //window.open(url[CurrentRequestCount], '_blank');
            setIsLoading(false);
            myStopFunction();
            return true;
        };
        script.onerror = function () {
          // alert(i + " is offline.");
          console.log(url[CurrentRequestCount] + " is offline.")
          CurrentRequestCount++;
          IsRequestProgress = false;
        };
        script.src = url[CurrentRequestCount];
      }
    }
    
    function myStopFunction() {
      clearInterval(myInterval);
    }
  }

  const ValidateMultipleUrl = async (url) => {
    if(typeof(url)==='string'){
      url = url.split();
    }
    setIsLoading(true);
    let IsURLRedirected = false;
    for (let i = 0; i<url.length; i++) {
      CheckAndRedirectURL(url[i])
    }
    
    function CheckAndRedirectURL(urlItem) {
      console.log(urlItem);
      setTimeout(function () {

        var script = document.body.appendChild(document.createElement("script"));
        script.onload = function () {
          console.log(script.src);
          if (IsURLRedirected == true) { }
          else {

            IsURLRedirected = true
            window.open(urlItem, '_blank');
            setIsLoading(false);
            return true;
          }

        };
        script.onerror = function () {
          // alert(i + " is offline.");
          console.log(urlItem + " is offline.")
        };
        script.src = urlItem;
      }, 3000);
    }
  }
  
  const SearchDetail = async (url, effective_date) => {
    let data = {
      "query": window.searchUI.searchQuery.query,
      "filters": {
        "all": [
          {
            "url": url,
          },
          {
            "effective_date": effective_date,
          }
        ]
      }
    }
    let response = await getSearchResults(data);

      const urlResponse = response.data.results[0].url.raw;

      if(typeof(urlResponse)==='string'){
        url = urlResponse.split();
      }

      const data2 = {
        "query": `"${url[0]}"`,
         "page": {
            // "current": window.searchUI.state.current,
            // "size": window.searchUI.state.resultsPerPage
            "current":1,
            "size": 1000
                  },
        "filters":{
          "all": []
        },
        "boosts":{
          "procedures":[
             {
                "type":"value",
                "value":"Procedures",
                "operation":"add",
                "factor":100.0
             }
          ],
          "source_name_ims":[
             {
                "type":"value",
                "value":"MARGO",
                "operation":"add",
                "factor":200.0
             }
          ],
          "source_sublevel":[
             {
                "type":"value",
                "value":"REGS-DE01 - CMS - Global Regulations",
                "operation":"add",
                "factor":20.0
             }
          ]
       },
                "precision":8,
                "search_fields":{
                  "system_title":{
                     "weight":3.5
                  },
                  "title":{
                    "weight":30.5
                 },
                 "tags":{
                    "weight":1.5
                 },
                 "division":{
                    "weight":1.5
                 },
                 "country":{
                    "weight":1.5
                 },
                 "description":{
                    "weight":1.0
                 },
                 "body":{
                    "weight":1.2
                 },
                  "approved":{
                     "weight":0.0
                  },
                  "approver":{
                     "weight":0.0
                  },
                  "author":{
                     "weight":0.0
                  },
                  "comments":{
                     "weight":0.0
                  },
                  "contact":{
                     "weight":0.0
                  },
                  "created_by":{
                     "weight":0.0
                  },
                  "doc_id":{
                     "weight":0.0
                  },
                  "editor":{
                     "weight":0.0
                  },
                  "enabling_and_support":{
                     "weight":0.0
                  },
                  "extension":{
                     "weight":0.0
                  },
                  "label":{
                     "weight":0.0
                  },
                  "languages":{
                     "weight":0.0
                  },
                  "org_level_1":{
                     "weight":0.0
                  },
                  "org_level_2":{
                     "weight":0.0
                  },
                  "org_level_3":{
                     "weight":0.0
                  },
                  "org_level_4":{
                     "weight":0.0
                  },
                  "org_level_5":{
                     "weight":0.0
                  },
                  "owned_by":{
                     "weight":0.0
                  },
                  "perm_id":{
                     "weight":0.0
                  },
                  "procedures":{
                     "weight":0.0
                  },
                  "process":{
                     "weight":0.0
                  },
                  "regulation":{
                     "weight":0.0
                  },
                  "site":{
                     "weight":0.0
                  },
                  "source_name_ims":{
                     "weight":0.0
                  },
                  "source_sublevel":{
                     "weight":0.0
                  }
               }
      };

      const response2 = await getSearchResults(data2);
      let totalResults = response2.data.meta.page.total_results;
      console.log('Total Results:', totalResults);

      //Start- if url contains pub then replaced with "Approved" and getting result count to show in UI
      let isMyDocURLFound = false;
      if(data2.query.indexOf(":pub") > -1) {        
        data2.query = data2.query.replace(":pub",":Approved");
        const response2_linkedBy=  await getSearchResults(data2);
        var totalResults_linkedBy = response2_linkedBy.data.meta.page.total_results;
        console.log('Total Results Approved:', totalResults_linkedBy);
        isMyDocURLFound = true;
      }
      //End- if url contains pub then replaced with "Approved" and getting result count to show in UI


      let org_level = [];
      if(response.data.results[0].org_level_1 && response.data.results[0].org_level_1.raw != 'not specified'){
        org_level.push(response.data.results[0].org_level_1.raw);
      }
      if(response.data.results[0].org_level_2 && response.data.results[0].org_level_2.raw != 'not specified'){
        org_level.push(response.data.results[0].org_level_2.raw)
      }
      if(response.data.results[0].org_level_3 && response.data.results[0].org_level_3.raw != 'not specified'){
        org_level.push(response.data.results[0].org_level_3.raw)
      }
      if(response.data.results[0].org_level_4 && response.data.results[0].org_level_4.raw != 'not specified'){
        org_level.push(response.data.results[0].org_level_4.raw)
      }
      if(response.data.results[0].org_level_5 && response.data.results[0].org_level_5.raw != 'not specified'){
        org_level.push(response.data.results[0].org_level_4.raw)
      }
      
      let reference_ = [];
      if(response.data.results[0].related_docs)      
      {
        if (response.data.results[0].related_docs.raw instanceof Array) 
        {
          reference_=[];
          response.data.results[0].related_docs.raw.forEach((item_,index) => 
          { 
            reference_.push(item_);
          });
        }
        else
        {
          reference_ = [];
          reference_.push(response.data.results[0].related_docs.raw);
        }
      }
      
      if (response.data.results[0].replaced_docs) 
      {
        reference_.push(response.data.results[0].replaced_docs.raw); 
      }

      SearchDetailList.title = response.data.results[0].title.raw;
      SearchDetailList.url = response.data.results[0].url.raw;
      SearchDetailList.description = response.data.results[0].description ? response.data.results[0].description.raw : '';
      SearchDetailList.contact = response.data.results[0].contact ? response.data.results[0].contact.raw : '';
      SearchDetailList.author = response.data.results[0].created_by ? response.data.results[0].created_by.raw : '';
      SearchDetailList.approver = response.data.results[0].owned_by ? response.data.results[0].owned_by.raw : '';
      SearchDetailList.effectiveDate = response.data.results[0].effective_date ? formatTimeandDate(response.data.results[0].effective_date.raw) : '';
      SearchDetailList.documentType = response.data.results[0].extension ? response.data.results[0].extension.raw : '';
      SearchDetailList.languages = response.data.results[0].languages ? response.data.results[0].languages.raw : '';

      SearchDetailList.orgLevel = org_level;
      //response.data.results[0].source_sublevel ? response.data.results[0].source_sublevel.raw : '';
      // ((response.data.results[0].org_level_1 ? (response.data.results[0].org_level_1.raw + " ") : "" )+
      // (response.data.results[0].org_level_2 ? (response.data.results[0].org_level_2.raw + " ") : "") +
      // (response.data.results[0].org_level_3 ? (response.data.results[0].org_level_3.raw + " ") : "" )+
      // (response.data.results[0].org_level_4 ? (response.data.results[0].org_level_4.raw + " ") : "" )+
      // (response.data.results[0].org_level_5 ? (response.data.results[0].org_level_5.raw) : "" ))
      // ;
      SearchDetailList.country = response.data.results[0].country ? response.data.results[0].country.raw : '';
      SearchDetailList.site = response.data.results[0].site ? response.data.results[0].site.raw : '';
      SearchDetailList.references = reference_;
      SearchDetailList.totalResults = response2.data.meta.page.total_results;

      SearchDetailList.totalResults_linkedBy = totalResults_linkedBy;
      SearchDetailList.isMyDocURLFound = isMyDocURLFound;

      setSearchDetailList(SearchDetailList);
      handleShow();
      console.log('SearchDetailList', SearchDetailList);

      
      return response;
    
  };
  const breadcrumbs = [
    {
      text: "My Saved Search",
      href: "",
    },
    {
      text: "Save Search",
      href: "",
    },
    {
      text: "Copy Search Link",
      href: "",
    },
  ];

const appendStringInTitle = (str) =>{
  //Added this function bcuz snippet title is missing "]" end 
  let count1 = (str.split("[").length - 1) ;
  let count2 = (str.split("]").length - 1) ;

  if(count1 > count2){
  str = str + "]";
  }
  return str;
}

const appendStringInBody = (str, searchValue) => {
  if (!searchValue || searchValue.trim() === "") {
    return '';
  }

  const words = str.split(/\b/);
  const searchWords = searchValue.toLowerCase().match(/\b\w+\b/g);

  const occurrences = [];
  let count = 0;
  let totalWordCount = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const lowercaseWord = word.toLowerCase();

    if (count >= 4 || totalWordCount >= 20) {
      break;
    }

    const searchWordFound = searchWords.some(searchWord => lowercaseWord.includes(searchWord));

    if (searchWordFound) {
      const startIndex = Math.max(0, i - 12);
      const endIndex = Math.min(i + 4, words.length);
      const nearbyWords = words.slice(startIndex, endIndex);

      const highlightedWords = nearbyWords.map(w => {
        const lowercaseW = w.toLowerCase();

        const searchWordIncluded = searchWords.some(searchWord => lowercaseW.includes(searchWord));

        if (searchWordIncluded) {
          const highlighted = searchWords.reduce((highlightedWord, searchWord) => {
            const lowercaseSearchWord = searchWord.toLowerCase();
            const searchIndex = lowercaseW.indexOf(lowercaseSearchWord);
            if (searchIndex !== -1 && lowercaseSearchWord.length >= 2) {
              const prefix = w.substring(0, searchIndex);
              const highlight = w.substring(searchIndex, searchIndex + lowercaseSearchWord.length);
              const suffix = w.substring(searchIndex + lowercaseSearchWord.length);
              const firstFour = prefix.substring(Math.max(0, prefix.length - 4), prefix.length);
              const lastFour = suffix.substring(0, Math.min(suffix.length, 4));
              return `${firstFour}<mark style="background-color: #ffff00">${highlight}</mark>${lastFour}`;
            }
            return highlightedWord;
          }, w);

          return highlighted;
        }

        return w;
      });

      occurrences.push(highlightedWords.join(" "));
      count++;
      totalWordCount += nearbyWords.length;
    }
  }

  if (occurrences.length > 0) {
    const highlightedBody = occurrences.join(" ");
    if (occurrences.length < 5 && totalWordCount < 20) {
      const remainingWords = words.slice(totalWordCount);
      return `${highlightedBody} ${remainingWords.slice(0, 20 - totalWordCount).join(" ")}...`;
    }
    return `${highlightedBody}...`;
  }

  return words.slice(0, 19).join(" ") + '...';
};



const handleContextMenu = (e, url) => {
  // Create a global variable to store the context menu element
var contextMenu;

// Function to hide the context menu
function hideContextMenu() {
  if (contextMenu && contextMenu.style.display !== "none") {
    contextMenu.style.display = "none";
  }
}

// Function to show the context menu
function showContextMenu(x, y) {
  contextMenu.style.left = x + "px";
  contextMenu.style.top = y + "px";
  contextMenu.style.display = "block";
}

// Add event listener for right-click on the document
document.addEventListener("contextmenu", function(event) {
  if (event.target.tagName === 'A') {
// Prevent the default context menu from appearing
event.preventDefault();
setClickedUrl(url);


  // Hide the previous context menu if it exists
  hideContextMenu();

  // Create the context menu
  contextMenu = document.createElement("div");
  contextMenu.style.position = "absolute";
  contextMenu.style.backgroundColor = "#f1f1f1";
  contextMenu.style.padding = "5px";
  contextMenu.style.border = "1px solid #ccc";
  contextMenu.style.borderRadius = "3px";
  contextMenu.style.boxShadow = "2px 2px 5px rgba(0, 0, 0, 0.3)";

  // Create the "Copy File Link" option
  var copyLinkOption = document.createElement("div");
  copyLinkOption.innerHTML = "Copy Link";
  copyLinkOption.style.cursor = "pointer";
  copyLinkOption.style.marginBottom = "5px";

  // Get the file link when the option is clicked
  copyLinkOption.addEventListener("click", function() {
    // Get the file link from the current URL
    var fileLink = url; 

if (Array.isArray(fileLink) && fileLink.length > 1) {// handle lifedoc multiple links
  fileLink = fileLink[0];
}

// Copy the file link to the clipboard
navigator.clipboard.writeText(fileLink).then(function() {
  console.log("File link copied to clipboard: " + fileLink);
}).catch(function(error) {
  console.error("Unable to copy file link: " + error);
});
    // Hide the context menu
    hideContextMenu();
  });

  // Add the "Copy File Link" option to the context menu
  contextMenu.appendChild(copyLinkOption);

  // Position the context menu based on the right-click event
  var mouseX = event.pageX;
  var mouseY = event.pageY;
  showContextMenu(mouseX, mouseY);

  // Add the context menu to the document
  document.body.appendChild(contextMenu);

  // Close the context menu when clicked outside
  document.addEventListener("click", function() {
    hideContextMenu();
  });
}});

// Add event listener to hide the context menu on scroll
window.addEventListener("scroll", function() {
  hideContextMenu();
});
  
  
}
  const CustomResultView = (result, onClickLink) => (
    
    <>
    
    <Container fluid>  
       
      <Row md="4" sm="2" xs="1" className="rowcontiner">
        <Col md="1">          
          <img
            src={getImg(result.result.source_name_ims.raw)}
            alt=""
            className="img_cls"
          />
        </Col>
        <Col md="8" className="titleCls">          
          <a
          onContextMenu={(e) => handleContextMenu(e, result.result.url.raw)}
          
            // onClick={onClickLink}
            onClick={() => { ValidateMultipleUrl_(result.result.url.raw)}}
            // href={result.result.url.raw}
            dangerouslySetInnerHTML={{ __html: (appendStringInTitle(result.result.title.snippet)) }}
            //target="_blank"
          />
          <p style={{ fontSize: 10 }} dangerouslySetInnerHTML={{ __html: appendStringInBody(result.result.body.raw, searchValue) }}></p>
        </Col>
        <Col md="2" className="dateCls">
        <span>{result.result.effective_date ? formatTimeandDate(result.result.effective_date.raw, false) : ''}</span>
        </Col>
        <Col md="1" className="iconCls" onClick={() => { SearchDetail(result.result.url.raw, result.result?.effective_date?.raw) }}>
          {/* <Col md="2" className="iconCls" onClick={handleShow}> */} 


          {/* <img src={infoicon} className="hide-on-hover" />
         <img src={infoiconhover} className="show-on-hover"/> */}

        </Col>
      </Row>
    </Container>
    </>
  );
  return (
    <>
    {
          IsLoading &&
          <div className="loader">
            <Spinner color="white" />
          </div>
        }
      {/* <div style={{ width: "100%" }}>
        <div style={{ width: "60%", display: "inline-block" }}>
          <EuiBreadcrumbs
            breadcrumbs={breadcrumbs}
            truncate={false}
            aria-label='An example of EuiBreadcrumbs'
          />
        </div>
        <div style={{ width: "12%", display: "inline-block" }}>
          <CreateCopyLinkSearch />
        </div>
        <div style={{ width: "10%", display: "inline-block" }}>          
          <CreateSaveSearch />
        </div>
        <div style={{ width: "14%", display: "inline-block" }}>
          <ExportExcel excelData={listExcelExportData} fileName={"Excel Export"} searchValue={searchValue} />
        </div>
      </div> */}


      {/* {listExcelExportData.length > 0 &&
      <ExportExcel excelData={listExcelExportData} fileName={"Excel Export"} />
      } */}

      <div className="sui-layout-main-header__inner">

        <div className="col-12">
          <div >
          <div style={{ display: "inline-block", width:"20%" }} className="col-md-2">{wasSearched && <PagingInfo />}</div>
          <div style={{ display: "inline-block", fontSize:"14px", fontFamily:"Arial",width:"20%" }}  className="col-md-2">
            <div style={{ display: "inline-block", fontWeight:"500" }} className="m-2">Sort by</div>
            <div style={{ display: "inline-block" }}>{wasSearched && <Sorting
              sortOptions={[
                {
                  name: "Relevance",
                  value: "",
                  direction: ""
                },
                {
                  name: "Title",
                  value: "title",
                  direction: "asc"
                },
                {
                  name: "Modified Date(Ascending)",
                  value: "effective_date",
                  direction: "asc"
                },
                {
                  name: "Modified Date(Descending)",
                  value: "effective_date",
                  direction: "desc"
                }
              ]}
            />}</div>
          </div>
          <div style={{ display: "inline-block" ,width:"12%"}}  className="col-md-2">
            {wasSearched && <ResultsPerPage />}
          </div>
          
          
            
            <div style={{ display: "inline-block" }}  className="col-md-2" >
              <div style={{marginRight: "24px",textAlign: "right"}}>
              <CreateCopyLinkSearch />
              </div>
            </div>
            <div style={{ display: "inline-block",width:"92px" }}  className="col-md-2">
              {/* <a href="" style={{cursor: "pointer", fontSize:14,height: "36px", fontWeight:"500"}}>Save Search</a> */}
              <CreateSaveSearch />
            </div>
            <div style={{ display: "inline-block" }}  className="col-md-2">
              <ExportExcel excelData={listExcelExportData} fileName={"Excel Export"} searchValue={searchValue} />
            </div>
            </div>

        </div>

      </div>
      
      
      <div>
        <Container fluid>
          <Row md="4" sm="2" xs="1" className="rowcontiner" style={{textAlign:"center"}}>
            <Col md="1">
              

            </Col>
            <Col md="8" className="titleCls">
              <strong>Title</strong>

            </Col>
            <Col md="2" className="dateCls">
             <strong>Effective Date</strong> 
            </Col>
            <Col md="1" >
              
            </Col>
          </Row>
        </Container>
      </div>
      <Results resultView={CustomResultView} />

      {/* <Results
        titleField='title'
        urlField='nps_link'
        thumbnailField={'http://sp-coll-bbs.bayer-ag.com/sites/030981/SiteAssets/Bayer_IMS/images/MARGO.png'}
        shouldTrackClickThrough={true}
      /> */}

      <Modal show={show} onHide={handleClose} className="searchDetailModal">
        <Modal.Header closeButton>

          <Modal.Title>{SearchDetailList_.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="col-xs-12">
          <p>Abstract</p>
            <table>
              <tr>
                <td style={{fontSize: 12.5}}>{SearchDetailList_.description}</td>
              </tr>
            </table>
            <hr></hr>
            <p>KEY ATTRIBUTES</p>
            <table>
              <tr>
                <td>Contacts(s)</td>
                <td>{SearchDetailList_.contact}</td>
              </tr>
              <tr>
                <td>Author(s)</td>
                <td>{SearchDetailList_.author}</td>
              </tr>
              <tr>
                <td>Approver(s)</td>
                <td>{SearchDetailList_.approver}</td>
              </tr>
              <tr>
                <td>Effective Date</td>
                <td>{SearchDetailList_.effectiveDate}</td>
              </tr>
              <tr>
                <td>Document Type</td>
                <td>{SearchDetailList_.documentType}</td>
              </tr>
              <tr>
                <td>Language</td>
                <td>{SearchDetailList_.languages}</td>
              </tr>
            </table>
            <hr></hr>
            <p>Ownership</p>
            <table>
              <tr>
                <td>OrgLevel(s)</td>
                <td>
                  {Array.isArray(SearchDetailList_.orgLevel) && SearchDetailList_.orgLevel.map((item) =>
                  <li>{item}</li>
                )}
                </td>
              </tr>
            </table>
            <hr></hr>
            <p>Scope</p>
            <table>
              <tr>
                <td>Country</td>
                <td>{SearchDetailList_.country}</td>
              </tr>
              <tr>
                <td>Site</td>
                <td>{SearchDetailList_.site}</td>
              </tr>
            </table>
            <hr></hr>
            {/* <table>
            <tr>
                <td><button class = "btn btn-primary" onClick={() =>{linkedby(SearchDetailList_.url)}}>Linked by</button></td>
                <td>Total Results: {SearchDetailList_.totalResults}</td>
                </tr>
            </table> */}
            <table>
            <tr>
                <td><button class = "btn btn-primary" onClick={() =>{linkedby(SearchDetailList_.url, SearchDetailList_.isMyDocURLFound, SearchDetailList_.totalResults)}}>Linked by</button></td>
                {/* <td>Total Results: {SearchDetailList_.totalResults}</td> */}
                {SearchDetailList_.isMyDocURLFound ? (
                  <td>Total Results MyDoc: {SearchDetailList_.totalResults_linkedBy + SearchDetailList_.totalResults} (Pub: {SearchDetailList_.totalResults}, Approved: {SearchDetailList_.totalResults_linkedBy}) </td>
                ) : (
                  <td>Total Results: {SearchDetailList_.totalResults}</td>
                )}
                </tr>
            </table>

            <hr></hr>
            <p>References</p>
            <table>
              <tr>
                <td>This Regulation references to</td>
                <td>
                {Array.isArray(SearchDetailList_.references) && SearchDetailList_.references.map((item) =>
                  <li>{item}</li>
                )}</td>
              </tr>
            </table>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};
