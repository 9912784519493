import React from "react";
import AppRoutes from "./core/routing/AppRoutes";
import "@elastic/eui/dist/eui_theme_light.css";
import { EuiProvider } from "@elastic/eui";
import "./App.scss";
import ExportExcel from "Excelexport";

function App() {
  const ExcelExportData = [
    {
      "First Name": "Sorabh",
      "Last Name": "Gotam",
      "Employee Code": "00001",
      "Mobile No": "99999999999",
      DOB: "01-01-0001",
      Address: "India",
    },
    {
      "First Name": "AB",
      "Last Name": "Gotam",
      "Employee Code": "00001",
      "Mobile No": "99999999999",
      DOB: "01-01-0001",
      Address: "India",
    },
    {
      "First Name": "ABCD",
      "Last Name": "Gotam",
      "Employee Code": "00001",
      "Mobile No": "99999999999",
      DOB: "01-01-0001",
      Address: "India",
    },
  ];
  return (
    <EuiProvider>
      <AppRoutes />
    </EuiProvider>
  );
}

export default App;
