import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "reactstrap";
import {
  ErrorBoundary,
  SearchProvider,
  Paging,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import { useParams } from "react-router-dom";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { queryProcessor } from "core/utils/processor";
import { Sidecontent } from "components/Sidecontent";
import { Searchbox } from "components/Searchbox";
import { Bodycontent } from "components/Bodycontent";
import { Appheader } from "components/Appheader";
import { facts } from "constants/facts";
// import FadeLoader from "react-spinners/FadeLoader";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import LoadingOverlay from "react-loading-overlay";
import { Appfooter } from "components/Appfooter";
import "./Homesearch.scss";
import connector from "services/search.service";
import { GetSavedSearchUUID,GetTranslatedResult_Service } from 'services/savedSearch.service';
import { useNavigate } from 'react-router-dom';
import { format ,subMonths} from "date-fns";
import CountryWithShortName,{ LanguageWithShortName } from "services/countrywithshortname";
import { async } from "q";
import { getSearchResults } from 'services/search.service';

export const Homesearch = () => {
  const navigate = useNavigate();
  const { searchTerm = "" } = useParams();
  const [config, setConfig] = useState({});
  const [isload, setisload] = useState(false);
  const [searchValue, setSearchValue] = useState(searchTerm);
  const [effectiveDate,setEffectiveDate]=useState("1950-10-05T14:48:00.000Z")
  const [showFilter,setshowFilter]=useState(false)
  const [searchTranslatedValue, setsearchTranslatedValue] = useState("");
  const [searchTranslatedWord, setsearchTranslatedWord] = useState("");  

  useEffect(() => {

    if(localStorage.getItem('sliderSaveCriteria')){
      setTimeout(()=>{
        
        debugger;
        ////////////////////////////
        let newValue = localStorage.getItem('sliderSaveCriteria');
        
        let from;
        let showfilter;
        newValue = newValue - 0; //converted string to number
        if (newValue == 0) {
          showfilter = false;
          from = "1950-10-05T14:48:00.000Z";
        }
        else if (newValue === 20) {
          showfilter = true;
          from = format(new Date().setDate(new Date().getDate() - 7));
        }

        else if (newValue === 40) {
          showfilter = true;
          from = format(new Date().setDate(new Date().getDate() - 30));

        } else if (newValue === 60) {
          showfilter = true;
          from = format(new Date().setDate(new Date().getDate() - 90));

        } else if (newValue === 80) {
          showfilter = true;
          from = format(new Date().setDate(new Date().getDate() - 180));

        } else if (newValue === 100) {
          showfilter = true;
          from = format(new Date().setDate(new Date().getDate() - 365));

        }
        ////////////////////////////



        //let from = format(new Date().setDate(new Date().getDate() - 7)); 
        getEffectiveDate(from,showfilter)
      },200);
    }
    
    if (window.location.href.includes('?q=')) { //added if on page load seach field is there bcuz it comes from saved search or copy link search
      setTimeout(() => { //set search name in searchBox 
        
        let param = new URLSearchParams(window.location.search);
        let queryStringValue = param.get('q');
        document.getElementsByClassName("sui-search-box__wrapper")[0].querySelector('input').value = queryStringValue;
      }, 5000);
      //below for click on checkbox procedure 
      setTimeout(() =>{
        if(window.location.search.includes('Procedure&filters'))
        {
          document.getElementById('procedures').click();
        }
      },3000)
    }
    getSearch(effectiveDate,showFilter);
    // setTimeout(()=>{
    //   var myClassName =  document.getElementsByClassName('sui-search-box__submit');
    //         var i = myClassName.length;
    //         myClassName[i-1].value = "  ";
    // },1500)
  }, [searchTerm, searchValue]);

  const getSearch = (effectiveDate,showfilter) => {
    let processedQuery = queryProcessor(
      searchValue === "*" ? " " : searchValue,
      connector,effectiveDate,showfilter
    );

    /* Check if string is valid UUID */
    function checkIfValidUUID(str) {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      return regexExp.test(str);
    }

    const GetSavedSearchResult = async (searchValue) => { // It will fetch savedSearch and display

      const response = await GetSavedSearchUUID(searchValue)
        ?.then((response) => {
          
          //this if condtion will filter data as per URL saved Search String (updated 02-13-2023)
          if (response.data.hits.hits[0]._source.query.lastPushSearchString) {
          //this change is for 'copy search link'
            if(!response.data.hits.hits[0]._source.finalNotificationAPIPayload){
              localStorage.setItem('sliderSaveCriteria', response.data.hits.hits[0]._source.dateValue)
            }
            else{
              //Setting date range value in local storage
              localStorage.setItem('sliderSaveCriteria', response.data.hits.hits[0]._source.finalNotificationAPIPayload.dateValue);
            }
            
        
            window.location.href = (window.location.origin + "/?" + response.data.hits.hits[0]._source.query.lastPushSearchString);
          }
          else{//this else condition will work for previous (02-13-2023) saved search
            //window.location.href = (window.location.origin + "/?size=n_20_n&filters%5B0%5D%5Bfield%5D=procedures&filters%5B0%5D%5Bvalues%5D%5B0%5D=Standard%20Operating%20Procedure&filters%5B0%5D%5Bvalues%5D%5B1%5D=Form%20Sheet&filters%5B0%5D%5Btype%5D=any");
            processedQuery.searchQuery = response.data.hits.hits[0]._source.query.searchQuery
            processedQuery.searchQuery.filters = response.data.hits.hits[0]._source.query.state.filters
  
            setConfig(processedQuery);
            setisload(true);
  
            setTimeout(() => { //set search name in searchBox 
              // alert('')       ;
              document.getElementsByClassName("sui-search-box__wrapper")[0].querySelector('input').value = processedQuery.searchQuery.query;
            }, 3500);
          }
          
          

          return response;
        })
        .catch((error) => { console.log(error) });

    }


    if (checkIfValidUUID(searchValue)) { // if searchValue is UUID then SavedSearch will be display
      GetSavedSearchResult(searchValue);
    }
    else {
      setTimeout(() => {
        // Start - MuleSoft API HR data - Priority user's country and language
        debugger;      
        if (sessionStorage.MuleApiHrData){
        const MuleApiHrData = JSON.parse(sessionStorage.MuleApiHrData);
        console.log("MuleApiHrData:", MuleApiHrData);
        
        if (MuleApiHrData.businessAddressCountry && MuleApiHrData.myServicesLanguage) {
          console.log("MuleApiHrDataFound");

          console.log("CountryAndLanguageShortCode :", CountryWithShortName);
          var CountryFullName = CountryWithShortName.filter(function (item) {
            return item.code.toLowerCase() == MuleApiHrData.businessAddressCountry.toLowerCase();
          });
          console.log("CountryFullName :", CountryFullName);

          console.log("LanguageWithShortNameCode :", LanguageWithShortName);
          var LanguageFullName = LanguageWithShortName.filter(function (item) {
            return item.code.toLowerCase() == MuleApiHrData.myServicesLanguage.toLowerCase();
          });
          console.log("LanguageFullName :", LanguageFullName);

          processedQuery.searchQuery.boosts = {
            "country": [
              {
                "type": "value",
                // "value": MuleApiHrData.businessAddressCountry,
                "value": CountryFullName[0].name,
                "operation": "add",
                "factor": 1000.0
              }
            ],
            "languages": [
              {
                "type": "value",
                // "value": MuleApiHrData.myServicesLanguage,
                "value": LanguageFullName[0].name,
                "operation": "add",
                "factor": 1000.0
              }
            ]
          }
        } else {
          console.log("MuleApiHrDataNotFound");
        }}
        // End - MuleSoft API HR data - Priority user's country and language

        setConfig(processedQuery);
        setisload(true);

        setTimeout(() => { //set search name in searchBox        
          document.getElementsByClassName("sui-search-box__wrapper")[0].querySelector('input').value = processedQuery.searchQuery.query;
          // button sui-search-box__submit
          // document.querySelector('.sui-search-box__submit:last-child').value+='NEW';
          // document.getElementsByClassName("sui-search-box__submit").value = '';

          document.getElementsByClassName('button sui-search-box__submit')[0].click();//added for forcefully click on searchbutton to reflect searchTerm.
          
        }, 100);

      }, 100);
    }

    //Regarding Translation Task -Start
    const getTranslatedTextSearchResult = (searchValue) => {

      var filters = {
        "all": []
      }

      var search_fields = {
        "system_title": {
          "weight": 3.5
        },
        "title":{
          "weight":30.5
       },
       "tags":{
          "weight":1.5
       },
       "division":{
          "weight":1.5
       },
       "country":{
          "weight":1.5
       },
       "description":{
          "weight":1.0
       },
       "body":{
          "weight":1.2
       },
        "approved": {
          "weight": 0.0
        },
        "approver": {
          "weight": 0.0
        },
        "author": {
          "weight": 0.0
        },
        "comments": {
          "weight": 0.0
        },
        "contact": {
          "weight": 0.0
        },
        "created_by": {
          "weight": 0.0
        },
        "doc_id": {
          "weight": 0.0
        },
        "editor": {
          "weight": 0.0
        },
        "enabling_and_support": {
          "weight": 0.0
        },
        "extension": {
          "weight": 0.0
        },
        "label": {
          "weight": 0.0
        },
        "languages": {
          "weight": 0.0
        },
        "org_level_1": {
          "weight": 0.0
        },
        "org_level_2": {
          "weight": 0.0
        },
        "org_level_3": {
          "weight": 0.0
        },
        "org_level_4": {
          "weight": 0.0
        },
        "org_level_5": {
          "weight": 0.0
        },
        "owned_by": {
          "weight": 0.0
        },
        "perm_id": {
          "weight": 0.0
        },
        "procedures": {
          "weight": 0.0
        },
        "process": {
          "weight": 0.0
        },
        "regulation": {
          "weight": 0.0
        },
        "site": {
          "weight": 0.0
        },
        "source_name_ims": {
          "weight": 0.0
        },
        "source_sublevel": {
          "weight": 0.0
        }

      }

      var boosts = {

        "procedures": [
          {
            "type": "value",
            "value": "Procedures",
            "operation": "add",
            "factor": 100.0
          }
        ],
        "source_name_ims": [
          {
            "type": "value",
            "value": "MARGO",
            "operation": "add",
            "factor": 200.0
          }
        ],
        "source_sublevel": [
          {
            "type": "value",
            "value": "REGS-DE01 - CMS - Global Regulations",
            "operation": "add",
            "factor": 20.0
          }
        ]

      }

      window.searchUI.state.filters.forEach((c, index) => {

        var obj = {}; // created object for json
        obj[c.type] = []; // passing name or key of object

        c.values.forEach((b, index) => {
          var obj1 = {};
          obj1[c.field] = b;
          obj[c.type].push(obj1);
        })

        filters.all.push(obj);

      });

      console.log('filterFacet :', filters);

      let data = {
        "query": searchValue,
        "result_fields": {
          "title": {
              "raw": {}
          }        
         },
        "page": {          
          "current": 1,
          "size": 1
        },
        filters
        , boosts,
        "precision": 8,
        search_fields
      }

      console.log('Data :', data);

      
      getSearchResults(data)
        ?.then((response) => {
          console.log("Translated Text Search Result response:", response);
          console.log("Translated Total Result Count:", response.data.meta.page.total_results);

          setsearchTranslatedValue(response.data.meta.page.total_results);
          setsearchTranslatedWord(searchValue);

          setTimeout(() => { //set search name in searchBox        
            document.getElementsByClassName('button sui-search-box__submit')[0].click();//added for forcefully click on searchbutton to reflect searchTerm.

          }, 1000);
        })
        .catch((error) => { console.log(error) });
    }

    
    const GetTranslatedResult = async (searchValue) => {
      const response = await GetTranslatedResult_Service(searchValue)
        ?.then((response) => {


          console.log("translated Response:", response)
          console.log("translated Text:", response.data.results[0])

          // if detect language is english Or search word and translated word both are same then msg should not show
          if(response.data.meta.detected_source_language[0] != "en" && response.data.results[0] != searchValue){
            debugger;
            getTranslatedTextSearchResult(response.data.results[0]);
            
            
          } else {
            setsearchTranslatedValue(0); //if detect is en then set total count 0 because its handle in show hide translate message.
          }
          
          return response;
        })
        .catch((error) => { console.log(error) });
    }
    if (searchValue) {

      debugger;
      GetTranslatedResult(searchValue)
      //  alert(searchValue);

    } else { setsearchTranslatedWord(searchValue); }

    //Regarding Translation Task -End
  };
const getEffectiveDate = (date,showfilter)=>{
  setshowFilter(showfilter)
  setEffectiveDate(date)
  setTimeout(()=>{

    getSearch(date,showfilter)
  },2000)

}
// useEffect(()=>{
//   console.log('herere')
//   getSearch()
// },[effectiveDate])
  return (
    <Container fluid>
      <Row>
        <Col md="12">
          {isload ? (
            <SearchProvider config={config}>
              <WithSearch
                mapContextToProps={({ wasSearched }) => ({
                  wasSearched,
                })}
              >
                {({ wasSearched }) => {
                  return (
                    <div>                      
                      <ErrorBoundary>
                        <Layout
                          header={
                            <>
                              {/* <Appheader /> */}
                              <Searchbox getSerchterm={setSearchValue} translatedWord={searchTranslatedWord} translatedResultCount={searchTranslatedValue} />
                            </>
                          }
                          sideContent={<Sidecontent getEffectiveDate={getEffectiveDate} />}
                          bodyContent={
                            <Bodycontent
                              wasSearched={wasSearched}
                              searchValue={searchValue}
                            />
                          }
                          bodyFooter={<Paging />}
                        />
                        <Appfooter />
                      </ErrorBoundary>
                    </div>
                  );
                }}
              </WithSearch>
            </SearchProvider>
          ) : (
            <LoadingOverlay
              active={true}
              spinner
              text="Loading ..."
            ></LoadingOverlay>
          )}
        </Col>
      </Row>
    </Container>
  );
};