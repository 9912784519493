import {format } from 'date-fns'
export const facts = {
  source_name_ims: {
    type: "value",

    name: "source_name",
    sort: { value: "asc" },
    // filters:{ "source_name":"LifeDoc"},
    size: 20,
  },
  regulation: { type: "value",  },
  procedures: { type: "value", size: 250 },
  enabling_and_support: { type: "value",  },
  country: { type: "value", size: 250 },
  extension: { type: "value",  },
  languages: { type: "value", size:50 },
  created_by: { type: "value", size: 10000 },
  tags: { type: "value", size: 5000 },
  // suggest: { type: "value",  },

  source_sublevel:{ type: 'value', size: 1000 },
  division:{type : 'value', size: 250},
  // last_modified_at:{type:'value'}
  effective_date: {
    type: "range",
    ranges:[
      { from: format(new Date().setDate(new Date().getDate() - 7)), to: new Date(), name: "Last 1 Week" },
      { from: format(new Date().setDate(new Date().getDate() - 30)), to: new Date(), name: "Last 1Months" },
      { from: format(new Date().setDate(new Date().getDate() - 90)), to: new Date(), name: "Last 3 Months" },
      { from: format(new Date().setDate(new Date().getDate() - 180)), to: new Date(), name: "Last 6 Months" },
      { from: format(new Date().setDate(new Date().getDate() - 365)), to: new Date(), name: "Last 1 Year" },
      { from :"1950-10-05T14:48:00.000Z", to:new Date(), name:"all"}
      
    ]
  }


  // modified_at: {
  //   type: 'range',

  //   ranges: [
  //     {
  //       from: '1972-04-13T12:48:33.420Z',
  //       name: 'Within the last 50 years',
  //     },
  //     {
  //       from: '1922-04-13T12:48:33.420Z',
  //       to: '1972-04-13T12:48:33.420Z',
  //       name: '50 - 100 years ago',
  //     },
  //     {
  //       to: '1922-04-13T12:48:33.420Z',
  //       name: 'More than 100 years ago',
  //     },
  //   ],
  // },
};
