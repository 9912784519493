import { Appheader } from "components/Appheader";
import { Row, Container, Col } from "reactstrap";
import bannerimg from "assets/images/Senior_man_on_footpath_IMS.jpg";
import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
} from "@elastic/eui";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "constants/routes";
import { Searchbox } from "components/Searchbox";
import { queryProcessor } from "core/utils/processor";
import { getSearchResults } from "services/search.service";
import { Sidecontent } from "components/Sidecontent";
import { Bodycontent } from "components/Bodycontent";
import { facts } from "constants/facts";
import {
  ErrorBoundary,
  SearchProvider,
  Paging,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { SearchBox } from "@elastic/react-search-ui";
import { HomebodyComponent } from "components/HomebodyComponent";
import { HomesearchBox } from "components/HomesearchBox";
import { Appfooter } from "components/Appfooter";
import "./Home.scss";
const end_point = process.env.REACT_APP_END_POINT;
const search_key = process.env.REACT_APP_SEARCH_KEY;
const engine_name = process.env.REACT_APP_ENGINE_NAME;
const connector = new AppSearchAPIConnector({
  searchKey: search_key,
  engineName: engine_name,
  endpointBase: end_point,
});
export const Home = () => {
  const navigate = useNavigate();

  const getsubmit = (searchvalue) => {
    navigate(routes.search.path.replace(":searchTerm", searchvalue));

  };
  return (
    <Container fluid className="home_page_main">
      {/* home page start */}

      <Row>
        <Col md="12">
          {/* <Appheader /> */}
          <HomesearchBox getsubmit={getsubmit} />
          <HomebodyComponent />
        </Col>
      </Row>
      <Appfooter />
    </Container>
  );
};
