import React, { Component, useMemo } from "react";
import { Layout, Result, SearchBox, PagingInfo, ResultsPerPage } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
// import * as SwiftypeAppSearch from "swiftype-app-search-javascript";
// import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import * as ElasticAppSearch from "@elastic/app-search-javascript";
import "./SearchPage.scss";
import { Container, Row, Col } from "reactstrap";
import { formatTimeandDate, getImg } from "core/utils/commonHelpers";
import infoicon from "assets/images/Icon-Info.png";
import infoiconhover from "assets/images/Icon-Info-Hover.png";
import arrow_right from "assets/images/landingpage/Link-Pfeil-greeen.png";
import { Pagination } from "components/Pagination";
import { getSearchResults } from "services/search.service";
import { Modal } from "react-bootstrap";
import { AutoComplete } from "antd";
import debounce from "lodash/debounce";
import { useParams } from "react-router-dom";
import withRouter from 'services/withRouter';
import { GetSavedSearchUUID } from 'services/savedSearch.service';

import ExportExcel from "Excelexport";
import { CreateSaveSearch } from "components/CreateSaveSearch";
import { CreateCopyLinkSearch } from "components/CopyLinkSearch";


import { Tooltip } from '@mui/material'
import exlIcon from "assets/images/Doc-Icon-XLS.png";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import {Spinner} from 'reactstrap'

const client = ElasticAppSearch.createClient({
  endpointBase: "https://sba-dev.ent.westeurope.azure.elastic-cloud.com",
  searchKey: "search-igw45th5aw6qrdixq1pn41n9",
  engineName: "ims",
});

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
// const connector = new AppSearchAPIConnector({
//   searchKey: search_key,
//   engineName: engine_name,
//   endpointBase: end_point,
// });
const { Option } = AutoComplete;
let PageSize = 20;
const querySuggestionOptions = {
  size: 10,
  types: {
    documents: {
      fields: ["title"],
    },
  },
};
let SearchDetailList = {};

 class SearchPage extends Component {
  
  state = {
    searchTerm: "",
    results: null,
    source_sublevel: "",
    procedures: "",
    content_source: "",
    source_name_ims: "",

    showprocedures: false,
    showfirstlevel: false,
    showsecondlevel: false,

    showsource: false,
    showregulation: false,
    // showenabling_and_support: false,
    showcountry: false,
    showextension: false,
    showlanguages: false,
    showcreated_by: false,
    ids: [],
    ids1: [],
    ids2: [],
    sourceids: [],
    regulationids: [],
    knowledgeids: [],
    fileids: [],
    Countryids: [],
    languagesids: [],
    authorids: [],
    currentPage: 1,
    totalrecords: 0,
    SearchDetailList_ :'',
    show:false,
    suggetionList: [],
    IsLoading : false
  };
  // const [currentPage, setCurrentPage] = useState(1);

  currentTableData = () => {
    const firstPageIndex = (this.state.currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return this.state.results.results.slice(firstPageIndex, lastPageIndex);
  };

  /*
    Converts filters object to array syntax for API
  */
  getFiltersArray(filters) {
    const {
      source_sublevel,
      procedures,
      content_source,
      source_name_ims,
      regulation,
      // enabling_and_support,
      country,
      extension,
      languages,
      created_by,
    } = filters;

    let arr = [];

    if (source_sublevel) {
      arr = [{ source_sublevel }, ...arr];
    }

    if (procedures) {
      arr = [{ procedures }, ...arr];
    }

    if (content_source) {
      arr = [{ content_source }, ...arr];
    }
    if (source_name_ims) {
      arr = [{ source_name_ims }, ...arr];
    }
    if (regulation) {
      arr = [{ regulation }, ...arr];
    }
    // if (enabling_and_support) {
    //   arr = [{ enabling_and_support }, ...arr];
    // }
    if (country) {
      arr = [{ country }, ...arr];
    }
    if (extension) {
      arr = [{ extension }, ...arr];
    }
    if (languages) {
      arr = [{ languages }, ...arr];
    }
    if (created_by) {
      arr = [{ created_by }, ...arr];
    }

    return arr;
  }

   /* Check if string is valid UUID */
   checkIfValidUUID(str) {
     // Regular expression to check if string is a valid UUID
     const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

     return regexExp.test(str);
   }


   componentDidMount() {
    // if searchValue is UUID then SavedSearch will be display
     if (this.checkIfValidUUID(this.props.params.searchTerm)) { 
      debugger;
       this.getSearch();
       return true;
     }
    
    
    this.setState({
      searchTerm:this.props.params.searchTerm == '*' ? '' : this.props.params.searchTerm
    })
    // setTimeout(()=>{
      this.search().then((results) => {
        this.setState({
          results,
          totalrecords: results.info.meta.page.total_results,
        });
      });
    // },3000)s
    setTimeout(() => {
      this.currentTableData();
    }, 5000);

    
  }
  search = async (newFilters) => {
    debugger;
    let promises = [];

    const updatedFilters = { ...this.state, ...newFilters };
    this.setState({
      searchTerm : (!this.state.searchTerm ? "" : this.state.searchTerm)
    });
    promises = [
      ...promises,
      client.search(this.state.searchTerm, {
        filters: {
          all: this.getFiltersArray(updatedFilters),
        },
        page: { size: 20, current: this.state.currentPage },
        // from : 0,size :2010000,
        // count:true,
        facets: {
          // On each search we perform, we will fetch all of the facets we
          // need, and simply not show the ones we do not need.
          source_sublevel: [
            {
              type: "value",
            },
          ],
          procedures: [
            {
              type: "value",
            },
          ],
          content_source: [
            {
              type: "value",
            },
          ],
          source_name_ims: [
            {
              type: "value",
            },
          ],
          regulation: [
            {
              type: "value",
            },
          ],
          // enabling_and_support: [
          //   {
          //     type: "value",
          //   },
          // ],
          country: [
            {
              type: "value",
            },
          ],
          extension: [
            {
              type: "value",
            },
          ],
          languages: [
            {
              type: "value",
            },
          ],
          created_by: [
            {
              type: "value",
            },
          ],
        },
      }),
    ];

    // If we've filtered on our first dimension, we won't have
    // counts for ALL of our 1st level dimensions, we'd only have a count
    // for the currently selected dimension. We'll do a separate query
    // to get our first dimension's facet counts, and merge them into the
    // counts we get back from our search query.
    if (updatedFilters.source_sublevel) {
      promises = [...promises, this.getsource_sublevelFacets()];
    }

    // Ditto
    if (updatedFilters.procedures) {
      promises = [...promises, this.getproceduresFacets(updatedFilters)];
    }

    // Ditto
    if (updatedFilters.content_source) {
      promises = [...promises, this.getcontent_sourceFacets(updatedFilters)];
    }
    if (updatedFilters.source_name_ims) {
      promises = [...promises, this.getsource_name_imsFacets(updatedFilters)];
    }
    if (updatedFilters.regulation) {
      promises = [...promises, this.getregulationFacets(updatedFilters)];
    }
    // if (updatedFilters.enabling_and_support) {
    //   promises = [
    //     ...promises,
    //     this.getenabling_and_supportFacets(updatedFilters),
    //   ];
    // }
    if (updatedFilters.showcountry) {
      promises = [...promises, this.getcountryFacets(updatedFilters)];
    }
    if (updatedFilters.extension) {
      promises = [...promises, this.getextensionFacets(updatedFilters)];
    }
    if (updatedFilters.languages) {
      promises = [...promises, this.getlanguagesFacets(updatedFilters)];
    }
    if (updatedFilters.created_by) {
      promises = [...promises, this.getcreated_byFacets(updatedFilters)];
    }

    const [
      results,
      source_sublevelFacets,
      proceduresFacets,
      content_sourceFacets,
      source_name_imsFacets,
      regulationFacets,
      // enabling_and_supportFacets,
      countryFacets,
      extensionFacets,
      languagesFacets,
      created_byFacets,
    ] = await Promise.all(promises);

    const facets = {
      ...source_sublevelFacets,
      ...proceduresFacets,
      ...content_sourceFacets,
      ...source_name_imsFacets,
      ...regulationFacets,
      // ...enabling_and_supportFacets,
      ...countryFacets,
      ...extensionFacets,
      ...languagesFacets,
      ...created_byFacets,
    };

    // As mentioned above, we merge all of our individual facet queries back
    // into our search query results.
    results.info.facets = { ...results.info.facets, ...facets };
window.state = this.state;
    return results;
  };

   searchExcelExport = async (newFilters) => {
     debugger;
     let promises = [];

     const updatedFilters = { ...this.state, ...newFilters };

     promises = [
       ...promises,
       client.search(this.state.searchTerm, {
         filters: {
           all: this.getFiltersArray(updatedFilters),
         },
         page: { size: 999, current: 1 },
         // from : 0,size :2010000,
         // count:true,
         facets: {
           // On each search we perform, we will fetch all of the facets we
           // need, and simply not show the ones we do not need.
           source_sublevel: [
             {
               type: "value",
             },
           ],
           procedures: [
             {
               type: "value",
             },
           ],
           content_source: [
             {
               type: "value",
             },
           ],
           source_name_ims: [
             {
               type: "value",
             },
           ],
           regulation: [
             {
               type: "value",
             },
           ],
           // enabling_and_support: [
           //   {
           //     type: "value",
           //   },
           // ],
           country: [
             {
               type: "value",
             },
           ],
           extension: [
             {
               type: "value",
             },
           ],
           languages: [
             {
               type: "value",
             },
           ],
           created_by: [
             {
               type: "value",
             },
           ],
         },
       }),
     ];

     // If we've filtered on our first dimension, we won't have
     // counts for ALL of our 1st level dimensions, we'd only have a count
     // for the currently selected dimension. We'll do a separate query
     // to get our first dimension's facet counts, and merge them into the
     // counts we get back from our search query.
     if (updatedFilters.source_sublevel) {
       promises = [...promises, this.getsource_sublevelFacets()];
     }

     // Ditto
     if (updatedFilters.procedures) {
       promises = [...promises, this.getproceduresFacets(updatedFilters)];
     }

     // Ditto
     if (updatedFilters.content_source) {
       promises = [...promises, this.getcontent_sourceFacets(updatedFilters)];
     }
     if (updatedFilters.source_name_ims) {
       promises = [...promises, this.getsource_name_imsFacets(updatedFilters)];
     }
     if (updatedFilters.regulation) {
       promises = [...promises, this.getregulationFacets(updatedFilters)];
     }
     // if (updatedFilters.enabling_and_support) {
     //   promises = [
     //     ...promises,
     //     this.getenabling_and_supportFacets(updatedFilters),
     //   ];
     // }
     if (updatedFilters.showcountry) {
       promises = [...promises, this.getcountryFacets(updatedFilters)];
     }
     if (updatedFilters.extension) {
       promises = [...promises, this.getextensionFacets(updatedFilters)];
     }
     if (updatedFilters.languages) {
       promises = [...promises, this.getlanguagesFacets(updatedFilters)];
     }
     if (updatedFilters.created_by) {
       promises = [...promises, this.getcreated_byFacets(updatedFilters)];
     }

     const [
       results,
       source_sublevelFacets,
       proceduresFacets,
       content_sourceFacets,
       source_name_imsFacets,
       regulationFacets,
       // enabling_and_supportFacets,
       countryFacets,
       extensionFacets,
       languagesFacets,
       created_byFacets,
     ] = await Promise.all(promises);

     const facets = {
       ...source_sublevelFacets,
       ...proceduresFacets,
       ...content_sourceFacets,
       ...source_name_imsFacets,
       ...regulationFacets,
       // ...enabling_and_supportFacets,
       ...countryFacets,
       ...extensionFacets,
       ...languagesFacets,
       ...created_byFacets,
     };

     // As mentioned above, we merge all of our individual facet queries back
     // into our search query results.
     results.info.facets = { ...results.info.facets, ...facets };
    //  window.state = this.state;
     return results;
   };
   listExcelExportData = [];
   
   makeData = async (resultData, searchValue) => {

    debugger;
    console.log("ExpotData:",resultData);
    resultData.rawResults.forEach((td, index) => {
       let objExcelExportData = {
         Title: '',
         URL: '',
         Search_Term: ''
       };
       objExcelExportData.Title = td.title && td.title.raw ? td.title.raw : '';
       objExcelExportData.URL = td.title && td.url.raw ? td.url.raw : '';
       objExcelExportData.Search_Term = searchValue;

       this.listExcelExportData.push(objExcelExportData);
     })
     setTimeout(() => {
       this.SaveExcelFile("Exported Data");

     }, 3000);
     console.log("listExcelExportData : ", this.listExcelExportData);
   }
   ExcelExport = async => {
     this.searchExcelExport().then((results) => {
      this.makeData(results, this.state.searchTerm);
      //  this.setState({
      //    results,
      //    totalrecords: results.info.meta.page.total_results,
      //  });
     });
   }
   SaveExcelFile = (fileName) =>{
    // const ws = XLSX.utils.json_to_sheet(excelData);
    const ws = XLSX.utils.json_to_sheet(this.listExcelExportData);
    const wb= {Sheets: {'data': ws}, SheetNames:['data']};
    const excelBugger = XLSX.write(wb, {bookType: 'xlsx', type:'array'});
    const data = new Blob([excelBugger], {type:fileType});
    this.setState({
      IsLoading : false
    });
    FileSaver.saveAs(data,fileName + fileExtension);
}
   exportToExcel = async () => {
    this.setState({
      IsLoading : true,
    });
    let searchValue = "IMS_Search_Export";
    this.ExcelExport();
  }

  getcountryFacets = async () => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,
      page: {
        size: 20,
        current: this.state.currentPage,
      },
      facets: {
        created_by: [
          {
            type: "value",
          },
        ],
      },
    });
    return results;
  };
  getlanguagesFacets = async () => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,
      page: {
        size: 20,
        current: this.state.currentPage,
      },
      facets: {
        languages: [
          {
            type: "value",
          },
        ],
      },
    });
    return results;
  };
  getextensionFacets = async () => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,
      page: {
        size: 20,
        current: this.state.currentPage,
      },
      facets: {
        country: [
          {
            type: "value",
          },
        ],
      },
    });
    return results;
  };

  getcountryFacets = async () => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,
      page: {
        size: 20,
        current: this.state.currentPage,
      },
      facets: {
        country: [
          {
            type: "value",
          },
        ],
      },
    });
    return results.info.facets;
  };
  // getenabling_and_supportFacets = async () => {
  //   const results = await client.search(this.state.searchTerm, {
  //     query: this.state.searchTerm,

  //     page: {
  //       size: 20,
  //       current: this.state.currentPage,
  //     },
  //     facets: {
  //       enabling_and_support: [
  //         {
  //           type: "value",
  //         },
  //       ],
  //     },
  //   });
  //   return results.info.facets;
  // };
  getregulationFacets = async () => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,

      page: {
        size: 20,
        current: this.state.currentPage,
      },
      facets: {
        regulation: [
          {
            type: "value",
          },
        ],
      },
    });
    return results.info.facets;
  };
  getsource_name_imsFacets = async () => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,

      page: {
        size: 20,
        current: this.state.currentPage,
      },
      facets: {
        source_name_ims: [
          {
            type: "value",
          },
        ],
      },
    });
    return results.info.facets;
  };
  getsource_sublevelFacets = async () => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,

      page: {
        size: 20,
        current: this.state.currentPage,
      },
      facets: {
        source_sublevel: [
          {
            type: "value",
          },
        ],
      },
    });
    return results.info.facets;
  };

  getproceduresFacets = async (newFilters) => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,

      page: {
        size: 20,
        current: this.state.currentPage,
      },
      filters: {
        all: [{ source_sublevel: newFilters.source_sublevel }],
      },
      facets: {
        procedures: [
          {
            type: "value",
          },
        ],
      },
    });
    return results.info.facets;
  };

  getcontent_sourceFacets = async (newFilters) => {
    const results = await client.search(this.state.searchTerm, {
      query: this.state.searchTerm,
      page: {
        size: 20,
        current: this.state.currentPage,
      },
      filters: {
        all: [
          { source_sublevel: newFilters.source_sublevel },
          { procedures: newFilters.procedures },
        ],
      },
      facets: {
        content_source: [
          {
            type: "value",
          },
        ],
      },
    });
    return results.info.facets;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.search().then((results) => {
      this.setState({
        results,
        current: this.state.currentPage,
        totalrecords: results.info.meta.page.total_results,
      });
    });
  };

  onChange = (e) => {
    const searchTerm = e;
    this.setState({
      searchTerm,
    });
  };
  handleCreatedBy = (created_by) => {
    this.search({ created_by }).then((results) => {
      this.setState({
        results,
        created_by,
        totalrecords: results.info.meta.page.total_results,

        // source_sublevel: "",
        // procedures: "",
        // content_source: "",
        // source_name_ims:"",
      });
    });
  };
  handLeanguages = (languages) => {
    this.search({ languages }).then((results) => {
      this.setState({
        results,
        languages,
        totalrecords: results.info.meta.page.total_results,

        // source_sublevel: "",
        // procedures: "",
        // content_source: "",
        // source_name_ims:"",
      });
    });
  };
  handleExtension = (extension) => {
    this.search({ extension }).then((results) => {
      this.setState({
        results,
        extension,
        totalrecords: results.info.meta.page.total_results,

        // source_sublevel: "",
        // procedures: "",
        // content_source: "",
        // source_name_ims:"",
      });
    });
  };
  handleCountry = (country) => {
    this.search({ country }).then((results) => {
      this.setState({
        results,
        country,
        totalrecords: results.info.meta.page.total_results,

        // source_sublevel: "",
        // procedures: "",
        // content_source: "",
        // source_name_ims:"",
      });
    });
  };
  // handleClickenabling_and_support = (enabling_and_support) => {
  //   this.search({ enabling_and_support }).then((results) => {
  //     this.setState({
  //       results,
  //       enabling_and_support,
  //       totalrecords: results.info.meta.page.total_results,

  //       // source_sublevel: "",
  //       // procedures: "",
  //       // content_source: "",
  //       // source_name_ims:"",
  //     });
  //   });
  // };
  handleClickregulation = (regulation) => {
    this.search({ regulation }).then((results) => {
      this.setState({
        results,
        regulation,
        totalrecords: results.info.meta.page.total_results,

        // source_sublevel: "",
        // procedures: "",
        // content_source: "",
        // source_name_ims:"",
      });
    });
  };
  handleClicksource_name_ims = (source_name_ims) => {
    this.search({ source_name_ims }).then((results) => {
      this.setState({
        results,
        source_name_ims,
        totalrecords: results.info.meta.page.total_results,

        // source_sublevel: "",
        // procedures: "",
        // content_source: "",
      });
    });
  };
  handleClicksource_sublevel = (source_sublevel) => {
    this.search({ source_sublevel }).then((results) => {

      this.setState({
        results,
        source_sublevel,
        totalrecords: results.info.meta.page.total_results,

        // procedures: "",
        // content_source: "",
      });
    });
  };

  handleClickprocedures = (procedures) => {
    this.search({ procedures }).then((results) => {
      this.setState({
        results,
        procedures,
        content_source: "",
        totalrecords: results.info.meta.page.total_results,
      });
    });
  };

  handleClickcontent_source = (content_source) => {
    this.search({ content_source }).then((results) => {

      this.setState({
        results,
        content_source,
        totalrecords: results.info.meta.page.total_results,
      });
    });
  };

  clickMainhide = (value) => {
    this.setState({
      showprocedures: value,
    });
  };
  clicksourceMainshow = (value) => {
    this.setState({
      showsource: value,
    });
  };

  clickMainshow = (value) => {
    this.setState({
      showprocedures: value,
    });
  };
  showFirstLevel = (value, index) => {

    this.setState({
      showfirstlevel: value,
    });
  };
  hideFirstLevel = (value) => {
    this.setState({
      showfirstlevel: value,
    });
  };
  showSecondlevel = (value) => {
    this.setState({
      showsecondlevel: value,
    });
  };
  hideSecondlevel = (value) => {
    this.setState({
      showsecondlevel: value,
    });
  };
  showRegulation = (value) => {
    this.setState({
      showregulation: value,
    });
  };
  // showEnabling_and_support = (value) => {
  //   this.setState({
  //     showenabling_and_support: value,
  //   });
  // };
  showCountry = (value) => {
    this.setState({
      showcountry: value,
    });
  };
  showExtension = (value) => {
    this.setState({
      showextension: value,
    });
  };
  showLanguages = (value) => {
    this.setState({
      showlanguages: value,
    });
  };
  showCreatedby = (value) => {
    this.setState({
      showcreated_by: value,
    });
  };
  checkboxOnchange = (e, idx) => {
  };
  filterClear = (e) => {
    window.location.reload()
    // setTimeout(()=>{

    //   this.componentDidMount()
    // },2000)
    //   this.setState({
    //     currentPage: 1,

    //     showprocedures: false,
    //     showfirstlevel: false,
    //     showsecondlevel: false,

    //     showsource: false,
    //     showregulation: false,
    //     // showenabling_and_support: false,
    //     showcountry: false,
    //     showextension: false,
    //     showlanguages: false,
    //     showcreated_by: false,
    //     ids: [],
    //     ids1: [],
    //     ids2: [],
    //     sourceids: [],
    //     regulationids: [],
    //     knowledgeids: [],
    //     fileids: [],
    //     Countryids: [],
    //     languagesids: [],
    //     authorids: [],
    //   });
    // setTimeout(() => {
    //   this.currentTableData();
    // }, 5000);
    // // console.log('click')
    // // e.preventDefault
    // // window.location.href = window.location.href
  };
  setCurrentPage = (page) => {
    this.setState({
      currentPage: page,
    });
    this.search().then((results) => {
      this.setState({
        results,
        totalrecords: results.info.meta.page.total_results,
      });
    });
  };
  SearchDetail = async (url) =>{
    let data = {
      "query": this.state.searchTerm,
      "filters": {
        "all": [

            {
                "any": [
                    {
                        "url": url
                    }
                ]
            }
        ]
    }
    }
    let response = await getSearchResults(data)?.then((response) => {
            
      SearchDetailList.title = response.data.results[0].title.raw;
      SearchDetailList.contact = '';    
      SearchDetailList.author = response.data.results[0].author.raw;
      SearchDetailList.approver = '';    
      SearchDetailList.effectiveDate = '';    
      SearchDetailList.documentType = response.data.results[0].extension.raw;
      SearchDetailList.languages = response.data.results[0].languages.raw;
      
      SearchDetailList.orgLevel = response.data.results[0].source_sublevel.raw;

      SearchDetailList.country = response.data.results[0].country.raw;
      SearchDetailList.site = '';    
      SearchDetailList.references = '';
      





      this.setState({
        SearchDetailList_:SearchDetailList
      });
      this.handleShow();
      
      
      return response;
    })
    .catch((error) => { console.log(error) });;
    
    
    
  }
   handleClose = () => this.setState({
    show:false
   })
  handleShow = () => {
    this.setState({
    show:true
   });
  }
  handleSearch = (value) => {
    
      this.setState({
        searchTerm:value
      })
      if(value.length > 3){
      client
        .querySuggestion(value, querySuggestionOptions)
        .then((response) => {
          const appropriateResult = response.results.documents.map(
            ({ suggestion }) => suggestion
          );
          this.setState({
            suggetionList: appropriateResult,
          });
          // setResult(appropriateResult);
        })
        .catch((error) => console.error(`An error occured: ${error}`));
      }
    };
    
    
  onSelect = (e) => {
    this.setState({
      searchTerm:e
    })
    this.search().then((results) => {
      this.setState({
        results,
        totalrecords: results.info.meta.page.total_results,
      });
      
    });

  };
  buttonSubmit = (e)=>{
    this.search().then((results) => {
      this.setState({
        results,
        totalrecords: results.info.meta.page.total_results,
      });
    });
  }
  onChangeInput = (e)=>{
  }

  

  getSearch = () => {
    let searchValue = this.props.params.searchTerm;
    const GetSavedSearchResult = async (searchValue) => { // It will fetch savedSearch and display

      const response = await GetSavedSearchUUID(searchValue)
        ?.then((response) => {

          console.log('state',response.data.hits.hits[0]._source.query.state);
          this.setState({
            searchTerm: (this.props.params.searchTerm == '*' ? '' : response.data.hits.hits[0]._source.query.state.searchTerm)
          })   
          debugger;
          
            this.state = response.data.hits.hits[0]._source.query.state
            
            this.search().then((results) => {
              this.setState({
                results,
                totalrecords: results.info.meta.page.total_results,
              });
            });
          

          return response;
        })
        .catch((error) => { console.log(error) });

    }

    GetSavedSearchResult(searchValue)
    // if (checkIfValidUUID(searchValue)) { // if searchValue is UUID then SavedSearch will be display
    //   GetSavedSearchResult(searchValue);
    // }
    // else {
      
    // }
  };


  render() {
    return (
      <div>

{
    this.state.IsLoading &&(
    <div className="loader">
    <Spinner color="white" />
    </div>
    )
}
        <Layout
          header={
            <div className="divform">
              <div className="innerdiv">
                <AutoComplete
                  className="input_form_auto"
                  onSearch={debounce(this.handleSearch, 300)}
                  onSelect={(e) => this.onSelect(e)}
                  onChange={(e)=>this.onChangeInput(e)}
                  placeholder="Search for entity labels"
                  value={this.state.searchTerm}
                >
                  {this.state.suggetionList.map((suggestion) => (
                    <Option key={suggestion} value={suggestion}>
                      {suggestion}
                    </Option>
                  ))}
                </AutoComplete>
                <button className="btn_button_btn" onClick={(e)=>this.buttonSubmit(e)}>
                  <i class="fa fa-search search_icon"></i>
                </button>
                {/* <SearchBox
                  value={this.state.searchTerm}
                  onChange={this.onChange}
                  onSubmit={this.onSubmit}
                /> */}
                <a href="/#/SavedSearch" className="my_saved_search_searchPage">
                  <img src={arrow_right} />{" "}
                  <span className="span_cls">My Saved Searches</span>
                </a>
              </div>
            </div>
          }
          sideContent={
            <div>
              <div>
                <span className="filters">FILTERS</span>
                <span
                  className="clearFilter"
                  onClick={(e) => this.filterClear(e)}
                >
                  clearFilter
                </span>
              </div>
              {/* facet 1 start */}
              <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showsource ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.clicksourceMainshow(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.clicksourceMainshow(false);
                      }}
                    ></i>
                  )}
                  <span>System/liberary</span>
                </div>
                {this.state.showsource ? (
                  <>
                    {this.state.results && (
                      <>
                        {this.state.results.info.facets[
                          "source_name_ims"
                        ][0].data.map((source_name_imsFacet, idx) => (
                          <div key={source_name_imsFacet.value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span
                              className="selectable"
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   this.handleClicksource_name_ims(
                              //     source_name_imsFacet.value
                              //   );
                              // }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.sourceids.includes(idx)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const update =
                                    checked === true
                                      ? [...this.state.sourceids, idx]
                                      : this.state.sourceids.filter(
                                          (sorted) => sorted != idx
                                        );
                                  this.setState({
                                    sourceids: update,
                                  });
                                  e.preventDefault();
                                  this.handleClicksource_name_ims(
                                    source_name_imsFacet.value
                                  );
                                }}
                              ></input>
                              {source_name_imsFacet.value} (
                              {source_name_imsFacet.count})
                            </span>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : null}
              </div>
              {/* facet 1 end */}
              {/* facet 2 start */}
              <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showprocedures ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.clickMainshow(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.clickMainhide(false);
                      }}
                    ></i>
                  )}
                  <span>Regulation type</span>
                </div>
                {this.state.showprocedures ? (
                  <>
                    {this.state.results && (
                      <div>
                        {this.state.results.info.facets[
                          "source_sublevel"
                        ][0].data.map((source_sublevelFacet, idx) => (
                          <div key={source_sublevelFacet.value}>
                            <input
                              type="checkbox"
                              checked={this.state.ids.includes(idx)}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const update =
                                  checked === true
                                    ? [...this.state.ids, idx]
                                    : this.state.ids.filter(
                                        (sorted) => sorted != idx
                                      );
                                this.setState({
                                  ids: update,
                                });
                                this.showFirstLevel(checked);
                                this.handleClicksource_sublevel(
                                  source_sublevelFacet.value
                                );
                              }}
                            ></input>
                            {/* {!this.state.showfirstlevel ? (
                              <i
                                class="fa fa-square-o"
                                aria-hidden="true"
                                onClick={(e) => {
                                  this.showFirstLevel(true, i);
                                  e.preventDefault();
                                  this.handleClicksource_sublevel(
                                    source_sublevelFacet.value
                                  );
                                }}
                              ></i>
                            ) : (
                              <i
                                class="fa fa-check-square"
                                aria-hidden="true"
                                onClick={(e) => {
                                  this.hideFirstLevel(false, i);
                                }}
                              ></i>
                            )} */}
                            <>
                              <span
                                className="selectable"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleClicksource_sublevel(
                                    source_sublevelFacet.value
                                  );
                                }}
                              >
                                {source_sublevelFacet.value} (
                                {source_sublevelFacet.count})
                              </span>
                              {this.state.showfirstlevel ? (
                                <>
                                  {this.state.source_sublevel ===
                                    source_sublevelFacet.value && (
                                    <div>
                                      {this.state.results.info.facets[
                                        "procedures"
                                      ][0].data.map((proceduresFacet, idx) => (
                                        <div key={proceduresFacet.value}>
                                          &nbsp;&nbsp;
                                          <input
                                            type="checkbox"
                                            checked={this.state.ids1.includes(
                                              idx
                                            )}
                                            onChange={(e) => {
                                              const { checked } = e.target;
                                              const update =
                                                checked === true
                                                  ? [...this.state.ids1, idx]
                                                  : this.state.ids1.filter(
                                                      (sorted) => sorted != idx
                                                    );
                                              this.setState({
                                                ids1: update,
                                              });
                                              this.showSecondlevel(checked);
                                              this.handleClickprocedures(
                                                proceduresFacet.value
                                              );
                                            }}
                                          ></input>
                                          {/* {!this.state.showsecondlevel ? (
                                            <i
                                              class="fa fa-square-o"
                                              aria-hidden="true"
                                              onClick={(e) => {
                                                this.showSecondlevel(true);
                                                e.preventDefault();
                                                this.handleClickprocedures(
                                                  proceduresFacet.value
                                                );
                                              }}
                                            ></i>
                                          ) : (
                                            <i
                                              class="fa fa-check-square"
                                              aria-hidden="true"
                                              onClick={(e) => {
                                                this.hideSecondlevel(false);
                                              }}
                                            ></i>
                                          )} */}
                                          <span
                                            className="selectable"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.handleClickprocedures(
                                                proceduresFacet.value
                                              );
                                            }}
                                          >
                                            {proceduresFacet.value} (
                                            {proceduresFacet.count})
                                          </span>
                                          {this.state.showsecondlevel ? (
                                            <>
                                              {this.state.procedures ===
                                                proceduresFacet.value && (
                                                <div>
                                                  {this.state.results.info.facets[
                                                    "content_source"
                                                  ][0].data.map(
                                                    (
                                                      content_sourceFacet,
                                                      idx
                                                    ) => (
                                                      <div
                                                        key={
                                                          content_sourceFacet.value
                                                        }
                                                      >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <input
                                                          type="checkbox"
                                                          checked={this.state.ids2.includes(
                                                            idx
                                                          )}
                                                          onChange={(e) => {
                                                            const { checked } =
                                                              e.target;
                                                            const update =
                                                              checked === true
                                                                ? [
                                                                    ...this
                                                                      .state
                                                                      .ids2,
                                                                    idx,
                                                                  ]
                                                                : this.state.ids2.filter(
                                                                    (sorted) =>
                                                                      sorted !=
                                                                      idx
                                                                  );
                                                            this.setState({
                                                              ids2: update,
                                                            });
                                                            this.handleClickcontent_source(
                                                              content_sourceFacet.value
                                                            );
                                                          }}
                                                        ></input>
                                                        <span
                                                          className="selectable"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            this.handleClickcontent_source(
                                                              content_sourceFacet.value
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            content_sourceFacet.value
                                                          }{" "}
                                                          (
                                                          {
                                                            content_sourceFacet.count
                                                          }
                                                          )
                                                        </span>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          ) : null}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : null}
              </div>
              {/* facet 2 end */}

              
              {/* facet 3 start */}
              {/* <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showregulation ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.showRegulation(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.showRegulation(false);
                      }}
                    ></i>
                  )}
                  <span>regulation</span>
                </div>
                {this.state.showregulation ? (
                  <>
                    {this.state.results && (
                      <>
                        {this.state.results.info.facets[
                          "regulation"
                        ][0].data.map((regulationFacets, idx) => (
                          <div key={regulationFacets.value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span
                              className="selectable"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleClickregulation(
                                  regulationFacets.value
                                );
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.regulationids.includes(idx)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const update =
                                    checked === true
                                      ? [...this.state.regulationids, idx]
                                      : this.state.regulationids.filter(
                                          (sorted) => sorted != idx
                                        );
                                  this.setState({
                                    regulationids: update,
                                  });
                                }}
                              ></input>
                              {regulationFacets.value} ({regulationFacets.count}
                              )
                            </span>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : null}
              </div> */}
              {/* facet 3 end */}
              {/* facet 4 start */}
              {/* <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showenabling_and_support ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.showEnabling_and_support(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.showEnabling_and_support(false);
                      }}
                    ></i>
                  )}
                  <span>knowledge document</span>
                </div>
                {this.state.showenabling_and_support ? (
                  <>
                    {this.state.results && (
                      <>
                        {this.state.results.info.facets[
                          "enabling_and_support"
                        ][0].data.map((enabling_and_support, idx) => (
                          <div key={enabling_and_support.value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span
                              className="selectable"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleClickenabling_and_support(
                                  enabling_and_support.value
                                );
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.knowledgeids.includes(idx)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const update =
                                    checked === true
                                      ? [...this.state.knowledgeids, idx]
                                      : this.state.knowledgeids.filter(
                                          (sorted) => sorted != idx
                                        );
                                  this.setState({
                                    knowledgeids: update,
                                  });
                                }}
                              ></input>
                              {enabling_and_support.value} (
                              {enabling_and_support.count})
                            </span>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : null}
              </div> */}
              {/* facet 4 end */}
              {/* facet 5 start */}
              <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showcountry ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.showCountry(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.showCountry(false);
                      }}
                    ></i>
                  )}
                  <span>Geographical scope</span>
                </div>
                {this.state.showcountry ? (
                  <>
                    {this.state.results && (
                      <>
                        {this.state.results.info.facets["country"][0].data.map(
                          (country, idx) => (
                            <div key={country.value}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span
                                className="selectable"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleCountry(country.value);
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={this.state.Countryids.includes(idx)}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    const update =
                                      checked === true
                                        ? [...this.state.Countryids, idx]
                                        : this.state.Countryids.filter(
                                            (sorted) => sorted != idx
                                          );
                                    this.setState({
                                      Countryids: update,
                                    });
                                  }}
                                ></input>
                                {country.value} ({country.count})
                              </span>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </div>
              {/* facet 5 end */}
              {/* facet 6 start */}
              {/* <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showextension ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.showExtension(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.showExtension(false);
                      }}
                    ></i>
                  )}
                  <span>file type</span>
                </div>
                {this.state.showextension ? (
                  <>
                    {this.state.results && (
                      <>
                        {this.state.results.info.facets[
                          "extension"
                        ][0].data.map((extension, idx) => (
                          <div key={extension.value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span
                              className="selectable"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleExtension(extension.value);
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.fileids.includes(idx)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const update =
                                    checked === true
                                      ? [...this.state.fileids, idx]
                                      : this.state.fileids.filter(
                                          (sorted) => sorted != idx
                                        );
                                  this.setState({
                                    fileids: update,
                                  });
                                }}
                              ></input>
                              {extension.value} ({extension.count})
                            </span>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : null}
              </div> */}
              {/* facet 6 end */}
              {/* facet 7 start */}
              <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showlanguages ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.showLanguages(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.showLanguages(false);
                      }}
                    ></i>
                  )}
                  <span>languages</span>
                </div>
                {this.state.showlanguages ? (
                  <>
                    {this.state.results && (
                      <>
                        {this.state.results.info.facets[
                          "languages"
                        ][0].data.map((languages, idx) => (
                          <div key={languages.value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span
                              className="selectable"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handLeanguages(languages.value);
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.languagesids.includes(idx)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const update =
                                    checked === true
                                      ? [...this.state.languagesids, idx]
                                      : this.state.languagesids.filter(
                                          (sorted) => sorted != idx
                                        );
                                  this.setState({
                                    languagesids: update,
                                  });
                                }}
                              ></input>
                              {languages.value} ({languages.count})
                            </span>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : null}
              </div>
              {/* facet 7 end */}
              {/* facet 8 start */}
              <div className="parent_div">
                <div className="heading_div">
                  {!this.state.showcreated_by ? (
                    <i
                      class="fa fa-caret-right"
                      onClick={(e) => {
                        this.showCreatedby(true);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      onClick={(e) => {
                        this.showCreatedby(false);
                      }}
                    ></i>
                  )}
                  <span>author</span>
                </div>
                {this.state.showcreated_by ? (
                  <>
                    {this.state.results && (
                      <>
                        {this.state.results.info.facets[
                          "created_by"
                        ][0].data.map((created_by, idx) => (
                          <div key={created_by.value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span
                              className="selectable"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleCreatedBy(created_by.value);
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.authorids.includes(idx)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const update =
                                    checked === true
                                      ? [...this.state.authorids, idx]
                                      : this.state.authorids.filter(
                                          (sorted) => sorted != idx
                                        );
                                  this.setState({
                                    authorids: update,
                                  });
                                }}
                              ></input>
                              {created_by.value} ({created_by.count})
                            </span>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : null}
              </div>
              {/* facet 8 end */}
            </div>
          }
          bodyContent={

            
            <div>
              <div style={{ width: "100%" }}>
                <div style={{ width: "66%", display: "inline-block" }}>

                </div>
                <div style={{ width: "10%", display: "inline-block" }}>

                  <CreateCopyLinkSearch />

                </div>
                <div style={{ width: "10%", display: "inline-block" }}>
                  {/* <a href="" style={{cursor: "pointer", fontSize:14,height: "36px", fontWeight:"500"}}>Save Search</a> */}
                  
                  <CreateSaveSearch />
                </div>
                <div style={{ width: "14%", display: "inline-block" }}>
                  {/* <ExportExcel excelData={"listExcelExportData"} fileName={"Excel Export"} searchValue={"searchValue"} /> */}
                  <Tooltip title="Excel Export">
                    <a onClick={(e) => this.exportToExcel()} style={{ cursor: "pointer", fontSize: 14, height: "36px" }}><img src={exlIcon} style={{ width: "18%", marginBottom: "-5px" }} />Export Results</a>
                  </Tooltip>
                </div>
              </div>
    <PagingInfo totalResults={this.state.totalrecords}/>


              {this.state.results &&
                this.state.results != null &&
                this.state.results.results.map((result) => (
                  <Container fluid>
                    <Row md="4" sm="2" xs="1" className="rowcontiner">
                      <Col md="1">
                        <img
                          src={getImg(result.getRaw("source_name_ims"))}
                          alt=""
                          className=" img_clsrow"
                        />
                      </Col>
                      <Col md="5" className="titleCls">
                        <a
                          // onClick={onClickLink}
                          href={result.getRaw("url")}
                          dangerouslySetInnerHTML={{
                            __html: result.getRaw("title"),
                          }}
                          target="_blank"
                        />
                      </Col>
                      <Col md="4" className="dateCls">
                        <span>
                          {formatTimeandDate(
                            result.getRaw("effective_date"),
                            false
                          )}
                        </span>
                      </Col>
                      <Col md="2" className="iconCls iconClass" onClick={() => { this.SearchDetail(result.data.url.raw) }}>
                        {/* <img src={infoicon} className="hide-on-hover" /> */}
                        <img src={infoiconhover} className="show-on-hover" onClick={() => { this.SearchDetail(result.result.url.raw) }} />
                      </Col>
                    </Row>
                  </Container>
                ))}
              <Pagination
                className="pagination-bar"
                currentPage={this.state.currentPage}
                totalCount={this.state.totalrecords}
                pageSize={PageSize}
                onPageChange={(page) => this.setCurrentPage(page)}
              />
            </div>
          }
          // bodyFooter={<Paging />}
        />
         <Modal show={this.state.show} onHide={this.handleClose} className="searchDetailModal">
        <Modal.Header closeButton>
          
          <Modal.Title>{this.state.SearchDetailList_ .title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <div className="col-xs-12">
        
          <p>KEY ATTRIBUTES</p>
            <table>
              <tr>
                <td>Contacts(s)</td>
                <td>{this.state.SearchDetailList_ .contact}</td>                
              </tr>
              <tr>
                <td>Author(s)</td>
                <td>{this.state.SearchDetailList_ .author}</td>                
              </tr>
              <tr>
                <td>Approver(s)</td>
                <td>{this.state.SearchDetailList_ .approver}</td>                
              </tr>
              <tr>
                <td>Effective Date</td>
                <td>{this.state.SearchDetailList_ .effectiveDate}</td>                
              </tr>
              <tr>
                <td>Document Type</td>
                <td>{this.state.SearchDetailList_ .documentType}</td>                
              </tr>
              <tr>
                <td>Language</td>
                <td>{this.state.SearchDetailList_ .languages}</td>                
              </tr>
            </table>
            <hr></hr>
            <p>Ownership</p>
            <table>
              <tr>
                <td>OrgLevel(s)</td>
                <td>{this.state.SearchDetailList_ .orgLevel}</td>                
              </tr>              
            </table>
            <hr></hr>
            <p>Scope</p>
            <table>
              <tr>
                <td>Country</td>
                <td>{this.state.SearchDetailList_ .country}</td>                
              </tr>  
              <tr>
                <td>Site</td>
                <td>{this.state.SearchDetailList_ .site}</td>                
              </tr>              
            </table>

            <hr></hr>
            <p>References</p>
            <table>
              <tr>
                <td>This Regulation references to</td>
                <td>{this.state.SearchDetailList_ .references}</td>                
              </tr>                            
            </table>
        </div>
        </Modal.Body>
        
      </Modal>
      </div>
    );
  }
}
export default withRouter(SearchPage);
