import axios from "axios";

const news_end_point = process.env.REACT_APP_NEWS_END_POINT;
const api_key = process.env.REACT_APP_NEWS_API_KEY;

const GetNews = () => {
    const headers = {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': api_key,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With'
    }
    
    return axios.get(news_end_point, {
        headers: headers
    })
};
export {
  GetNews
}