import React from 'react';
import { useRoutes } from 'react-router-dom';
import { PlainLayout } from 'layouts/PlainLayout';
import { Homesearch } from 'pages/HomesearchPage/Homesearch';
import { Home } from 'pages/Home/Home';
import { routes} from 'constants/routes';
import { Login } from 'pages/Login';
import { Imprint } from 'pages/Imprint';
import { SavedSearch } from 'pages/SavedSearch/SavedSearch';
import { Aboutims } from 'pages/Aboutims/Aboutims';
import { Countryorg } from 'pages/Countryorg';
import { Globelef } from 'pages/Globelef';
import  SearchPage from 'pages/SearchPage/SearchPage';
import { Feedback } from 'pages/Feedback';
function AppRoutes() {
  let element = useRoutes([
    {
      path: "/",
      element: <PlainLayout />,
      children: [
        {
          path: routes.search.path,
          element: <Homesearch />,
        },
        {
          path: routes.home.path,
          element: <Home />,
        },
        {
          path: routes.savedSearch.path,
          element: <SavedSearch />,
        },
        {
          path: routes.login.path,
          element: <Login />,
        },
        {
          path: routes.imprint.path,
          element: <Imprint />,
        },
        {
          path: routes.aboutims.path,
          element: <Aboutims />,
        },
        {
          path: routes.countryorg.path,
          element: <Countryorg />,
        },
        {
          path: routes.globelef.path,
          element: <Globelef />,
        },
        {
          path: routes.aboutims.path,
          element: <Aboutims />,
        },
        {
          path: routes.searchpage.path,
          // element: <Homesearch />,
          element: <SearchPage/>,
        },
        {
          path: routes.feedback.path,
          element: <Feedback />,
        },
        
      ],
    },
  ]);

  return element;
}
export default AppRoutes;
