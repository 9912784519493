import React, { useState,useEffect } from "react";
import { EuiTabs, EuiTab } from "@elastic/eui";
import "./HeadertabComponent.scss";
import { useNavigate } from 'react-router-dom';


const tabs = [
  {
    id: "home",
    name: "HOME",
    disabled: false,
    href: '/#/home'
  },
  {
    id: "aboutims",
    name: "ABOUT IMS",
    disabled: false,
    href: '/#/aboutims' 
  },
  // {
  //   id: "saved_searches",
  //   name: "SAVED SEARCHES",
  //   disabled: false,
  //   href: '/#/SavedSearch'
  // },
  {
    id: "search",
    name: "SEARCH",
    disabled: false,
    href: '/#/search/*'
  },
  {
    id: "globalEF",
    name: "GLOBAL EF",
    disabled: false,   
    href: '/#/globelef' 
  },  
  {
    id: "country_organisation",
    name: "COUNTRY ORGANIZATION",
    disabled: false,
    href: '/#/countryorganisation' 
  },
];
export const HeadertabComponent = () => {
  const navigate = useNavigate();
  const [selectedTabId, setSelectedTabId] = useState("home");

  const onSelectedTabChanged = (id) => {
    window.onbeforeunload = function() {
      localStorage.clear(); // Clear the local storage
    };
    setSelectedTabId(id);
  };

  useEffect(() => {
    
    //Added below condition for search page Refresh (hard Refresh or Forcefully refresh page )
    if(window.location.href.includes('size=n_') && window.location.hash == ''){
      
      
        setSelectedTabId('search');
      navigate('/search/*');
    }
    tabs.forEach(element => { //added for, if click by right section from home page
      if(element.href == ('/' + window.location.hash)){
        setSelectedTabId(element.id);
      }
    });
  }, []);

  const renderTabs = () => {
    return tabs.map((tab, index) => (
      <EuiTab
        // {...(tab.href && { href: tab.href, target: '_blank' })}
        // {...(tab.href && { href: tab.href })}
        onClick={() => onSelectedTabChanged(tab.id)}
        isSelected={tab.id === selectedTabId}
        disabled={tab.disabled}
        key={index}
        href={tab.href}
      >
        {tab.name}
      </EuiTab>
    ));
  };
  return <EuiTabs className="tabclass" bottomBorder={false}>{renderTabs()}</EuiTabs>;
};
