import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { Row, Container, Col, List } from "reactstrap";
import { EuiTitle, EuiListGroup } from "@elastic/eui";
import "./Feedback.scss";
import { routes } from "constants/routes";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

// import { Appfooter } from "components/Appfooter";

export const Feedback = () => {

  const [name, setName] = useState('');
  const navigate = useNavigate();

  // function to update state of name with
  // value enter by user in form
  const handleChange = (e) => {
    setName(e.target.value);

    // <a href="mailto:`{email}`?subject={subject}&body={body}">Click to Send an Email</a>
    // window.location.href = "mailto:email@example.com?subject='Hello from Abstract!'&body='Just popped in to say hello'";
    e.preventDefault();
  }
  const handleCancel = () => {

    navigate(routes.home.path);
  };

  const handleSubmit = (e) => {

    window.location.href = "mailto:ims_user_interface_feedback@bayer.com?subject=IMS Feedback&body=" + name;
  };


  return (
    <>
      <div className="App">
        <header className="feedback-header feedback-padding">
          <div className="row col-12">
            <div className="col-7">
              <form onSubmit={(e) => { handleSubmit(e) }}>
                {/*when user submit the form , handleSubmit()
        function will be called .*/}

                <div className="col-12">
                  <p className="float-md-start">Please share your feedback below.This feedback will be sent to IMS Team  at Corporate Office.</p><br />
                  <textarea className="float-md-start col-12" type="text" value={name} rows="10" required onChange={(e) => { handleChange(e) }} /><br />
                </div>
                <div className="col-12">
                  <div className="m-lg-2 float-md-end">
                    <Button variant="primary" type="submit" size="sm" className="me-2">Submit</Button>
                    <Button variant="secondary" size="sm" onClick={handleCancel}>Cancel</Button>
                  </div>
                </div>





              </form>
            </div>
          </div>

        </header>
      </div>
    </>
  );
};
