import React, { useState } from "react";
import { SearchBox } from "@elastic/react-search-ui";
// import { AutocompleteView } from 'components/AutocompleteView';
import "./Searchbox.scss";
import { routes } from "constants/routes";
import { useNavigate } from "react-router-dom";
import arrow_right from "assets/images/landingpage/Link-Pfeil-greeen.png";

export const Searchbox = (props) => {
  const navigate = useNavigate();

  const [searchValue, setsearchvalue] = useState("");
  const [searchTranslatedValue, setsearchTranslatedValue] = useState("");
  const [searchTranslatedWord, setsearchTranslatedWord] = useState("");

  const [showTranslatedMessage, setshowTranslatedMessage] = useState(false)


  const getSearchterm = (searchValue) => {
    props.getSerchterm(searchValue);
    
    setsearchvalue(searchValue);

    //Regarding Translation Task -Start
    setsearchTranslatedValue(props.translatedResultCount);
    setsearchTranslatedWord(props.translatedWord); 
      
    if(props.translatedWord !="" && props.translatedWord !="*" && props.translatedWord !="undefined" && props.translatedWord !='undefined' && props.translatedResultCount > 0){  
      setshowTranslatedMessage(true);
    } else {    
      setshowTranslatedMessage(false);
    }
    //Regarding Translation Task -End
  };

  const translationLink = () => {
    var currentURL = new URL(window.location.href);
    var derivedParameters = searchTranslatedWord;

      // Remove existing parameters
      currentURL.search = "";

      // Remove hash fragment
      currentURL.hash = "";
    
      // Add custom parameters
      currentURL.searchParams.set("q", derivedParameters);
      currentURL.searchParams.set("size", "n_20_n"); 
      currentURL.searchParams.set("", "");     
    
      // Navigate to the new URL
      window.open(currentURL.toString(), '_blank');
  };

  const Results = () => (
    <>
    <div className="translated-msg">
      Translation: {searchTranslatedWord} (Search Results:<a style={{cursor:"pointer"}} onClick={translationLink}> <u>{searchTranslatedValue}</u></a> documents)
    </div>
      
    {/* <div className="translated-msg-lower">
      If necessary, you can initiate this search manually with the {searchTranslatedValue} documents found.
    </div> */}
    </>
  )
const clicked = ()=>{
  let id = document.getElementsByClassName("sui-search-box__text-input ");
if (id.length > 0) {
  if( id[0].value.length !== 0 || null){
    getSearchterm(id[0].value)
  }
  else{
    getSearchterm("");
  }
}
};
  return (
    <div className="div_block">
      <div className="search_box_div">
        <SearchBox
          inputProps={{ placeholder: "Search for Regulations and Processes" }}
          autocompleteMinimumCharacters={3}
          onSubmit={(searchTerm) => {
            getSearchterm(searchTerm);
          }}
          
          // autocompleteResults={{
          //   sectionTitle: "Suggested Results",
          //   titleField: "title",
          //   urlField: "nps_link"
          // }}
          autocompleteSuggestions={true}
          debounceLength={300}
          onSelectAutocomplete={(
            selection,
            {},
            defaultOnSelectAutocomplete
          ) => {
            if (selection.suggestion) {
              defaultOnSelectAutocomplete(selection);

              getSearchterm(selection.suggestion);
            } else {
              defaultOnSelectAutocomplete(selection);
              
              //Regarding Translation Task -Start
              setsearchTranslatedValue(props.translatedResultCount);
              setsearchTranslatedWord(props.translatedWord);
              //Regarding Translation Task -Start
            }
          }}
        />
              <i class="fa fa-search homeSearch_icon" onClick={()=>clicked()}></i>

        <a href="/#/SavedSearch" className="my_saved_search_home" >
          <img src={arrow_right} /> <span className="span_cls">My Saved Searches</span>

      </a>
      {/* <div className="translated-msg">Translation: {searchTranslatedWord} (Search Results: {searchTranslatedValue} documents)</div> */}
      { showTranslatedMessage ? <Results /> : null }
        {/* <SearchBox
      inputProps={{ placeholder: 'Search for Regulations and Processes'}} 
      // value={searchValue}
        debounceLength={0}
        onSubmit={(searchTerm) => {
          getSearchterm(searchTerm)
        }}
        autocompleteResults={{
          titleField: "title",
          urlField: "url"
        }}
        searchAsYouType={true}
        autocompleteMinimumCharacters={3}
        onSelectAutocomplete={(selection, {}, defaultOnSelectAutocomplete) => {
          console.log(selection.title.snippet,'selection.suggestion')
          // getSearchterm(selection.title.snippet)

          // getSearchterm(selection.title.snippet)
          // if (selection.suggestion) {
          //   navigate("/search?q=" + selection.suggestion);
          // } else {
            defaultOnSelectAutocomplete(selection);
          // }
        }}
       
      /> */}
      </div>
    </div>
  );
};
