import { facts } from 'constants/facts';

const queryProcessor = (searchTerm, connector_new,effectiveDateFrom,showFilter) => {
  // console.log(showFilter,'showFilter')
  const config = {
    alwaysSearchOnInitialLoad: true,
    hasA11yNotifications: false,
    apiConnector: connector_new,
    searchQuery: {
      query: searchTerm,
      //precision: 11, //Lars suggested it regarding Ranking issue
      filters: showFilter ? [ // <-- add condition here
      {
        field: "effective_date",
        values: [{
          from: effectiveDateFrom, to: new Date(), name: "Last 1 Week"
        }]
      }
    ] : [],
      result_fields: {
        title: {
          snippet: {
            fallback: true,
            size:1000
          },
          
        },
        extension: {
          snippet: {
            fallback: true,
          },
        },
        modified_at: { raw: {} },
        source_name_ims: { raw: {} },
        url: { raw: {} },
        id: { raw: {} },
        body: { raw: {} },
        effective_date: {
         raw: {}
        }
      },
      facets: facts,
      disjunctiveFacets:['procedures','enabling_and_support','regulation','source_name_ims','country','languages','created_by','modified_at','tags','division','source_sublevel','effective_date'],

      //added below code for Boosting and get data as per ranking Devops Task - 15747

      "precision":8,
"boosts":{
   "procedures":[
      {
         "type":"value",
         "value":"Procedures",
         "operation":"add",
         "factor":100.0
      }
   ],
   "source_name_ims":[
      {
         "type":"value",
         "value":"MARGO",
         "operation":"add",
         "factor":200.0
      }
   ],
   "source_sublevel":[
      {
         "type":"value",
         "value":"REGS-DE01 - CMS - Global Regulations",
         "operation":"add",
         "factor":20.0
      }
   ]
},
"search_fields":{
   "system_title":{
      "weight":3.5
   },
   "title":{
      "weight":30.5
   },
   "tags":{
      "weight":1.5
   },
   "division":{
      "weight":1.5
   },
   "country":{
      "weight":1.5
   },
   "description":{
      "weight":1.0
   },
   "body":{
      "weight":1.2
   },
   "approved":{
      "weight":0.0
   },
   "approver":{
      "weight":0.0
   },
   "author":{
      "weight":0.0
   },
   "comments":{
      "weight":0.0
   },
   "contact":{
      "weight":0.0
   },
   "created_by":{
      "weight":0.0
   },
   "doc_id":{
      "weight":0.0
   },
   "editor":{
      "weight":0.0
   },
   "enabling_and_support":{
      "weight":0.0
   },
   "extension":{
      "weight":0.0
   },
   "label":{
      "weight":0.0
   },
   "languages":{
      "weight":0.0
   },
   "org_level_1":{
      "weight":0.0
   },
   "org_level_2":{
      "weight":0.0
   },
   "org_level_3":{
      "weight":0.0
   },
   "org_level_4":{
      "weight":0.0
   },
   "org_level_5":{
      "weight":0.0
   },
   "owned_by":{
      "weight":0.0
   },
   "perm_id":{
      "weight":0.0
   },
   "procedures":{
      "weight":0.0
   },
   "process":{
      "weight":0.0
   },
   "regulation":{
      "weight":0.0
   },
   "site":{
      "weight":0.0
   },
   "source_name_ims":{
      "weight":0.0
   },
   "source_sublevel":{
      "weight":0.0
   }
}

      , /*"boosts": {
        "procedures": [
          {
            "type": "value",
            "value": "Procedures",
            "operation": "multiply",
            "factor": 4.0
          }
        ],
        "source_name_ims": [
          {
            "type": "value",
            "value": "MARGO",
            "operation": "multiply",
            "factor": 3.0
          }
        ],
        "source_sublevel": [
          {
            "type": "value",
            "value": "REGS-DE01 - CMS - Global Regulations",
            "operation": "multiply",
            "factor": 2.0
          }
        ]
      },
      "search_fields": {
        "title": {
          "weight": 3.5
        },
        "tags": {
          "weight": 2.9
        },
        "division": {
          "weight": 1.9
        },
        "country": {
          "weight": 1.9
        },
        "description": {
          "weight": 0
        },
        "body": {
          "weight": 0
        }
      }*/
    },
    autocompleteQuery: {
      // results: {
      //   resultsPerPage: 5,
      //   result_fields: {
      //     title: {
      //       snippet: {
      //         fallback: true,
      //       },
      //     },
      //     extension: {
      //       snippet: {
      //         fallback: true,
      //       },
      //     },
      //     modified_at: { raw: {} },
      //     source_name_ims: { raw: {} },
      //     url: { raw: {} },
      //     id: { raw: {} },
      //   },
      // },
      suggestions: {
        types: {
          documents: {
            fields: ["title", "description"],
          },
        },
        size: 20,
      },
    },
    debug: true
  };
  return config;
};
export { queryProcessor };
