import React from 'react';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Appheader } from 'components/Appheader';
import { HeadertabComponent } from 'components/HeadertabComponent';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../src/pages/authConfig';
const msalInstance = new PublicClientApplication(msalConfig);

import { AzureAD } from 'react-aad-msal';
import { authProvider } from 'helpers/ssohelper'; 


ReactDOM.render(

  // <React.StrictMode>

  //     <HashRouter>
  //     <MsalProvider instance={msalInstance}>
  //     <div style={{ padding: "20px 56px 0px 56px" }}>
  //       <Appheader />
  //       <HeadertabComponent />
  //     </div>
  //       <App />
  //       </MsalProvider>

  //     {/* <App /> */}

  //     </HashRouter>
  // </React.StrictMode>,

  <AzureAD provider={authProvider} forceLogin={true}>
    <React.StrictMode>
      <HashRouter>
        <div style={{ padding: "20px 56px 0px 56px" }}>
          <Appheader />
          <HeadertabComponent />
        </div>
        <App />
      </HashRouter>
    </React.StrictMode>
  </AzureAD>,


  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
