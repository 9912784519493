import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import axios from "axios";

const end_point = process.env.REACT_APP_END_POINT;
const search_key = process.env.REACT_APP_SEARCH_KEY;
const engine_name = process.env.REACT_APP_ENGINE_NAME;
const connector = new AppSearchAPIConnector({
  searchKey: search_key,
  engineName: engine_name,
  endpointBase: end_point,
});


const getSearchResults = (data) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${search_key}`,
  };
  return axios.post(
    end_point + "/api/as/v1/engines/ims/search",
    data,
    {
      headers: headers,
    }
  );
};

export default connector
export { getSearchResults };
